@use "../../../../assets/styles/custom" as config;
@use "../../../../assets/styles/custom_animations";
@use "sass:map";



@media screen {
    .supportItemDescription_1{
        position: absolute;
        top: 40px;
        left: 200px;
        scale: 0.95;
        width: 280px;
    }
    .supportItemDescription_2{
        position: absolute;
        top: 47%;
        left: 250px;
        scale: 0.95;
        width: 250px;
    }
    .noteContainer{
        display: none;
    }
    .note {
        width: 100%;
        
        background: #FFFFFF;
        box-shadow: 0px 66.6667px 222.222px 77.7778px rgba(0, 0, 0, 0.1);
        border-radius: 25px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0.8rem 0.5rem;
        p{
            font-size: 0.85rem;
        }
        .noteDescription {
            padding-left: 0.5rem;
        }

        img {
            max-width: 70px;
            border-radius: 100px;
        }
    }

    .supportItem {
        // max-width: 400px;
        max-width: 100%;
        height: auto;

        h5 {
            font-family: "Typold Book";
        }

        p {
            font-family: "Typold Light";
            font-size: 1.05rem;
        }

        .supportItemImage {
            display: flex;
            justify-content: center;

            border-radius: 35px 35px 0px 0px;
            background-color: rgba(50, 87, 168, 0.2);
            width: 100%;
            height: 100%;

            img {
                width: calc(100%);
            }
        }

        .supportItemDescription {
            background-color: var(--color-primary);
            padding: 1rem;
            position: relative;
            top: -10px;
            color: #fff;
            border-radius: 10px 10px 0px 0px;
        }

    }

    .containerContact {
        display: flex;

        flex-direction: column;

        .button {
            /* --color-primary-gradient-horizontal: #fff;
            --izi-button-bg-color: var(--color-primary-gradient-horizontal) !important;
            --izi-button-color: var(--color-primary-blue) !important; */
            padding: 1rem;
            font-size: 0.7rem;

            svg {
                path {
                    fill: var(--izi-button-color);
                }
            }

            &:hover {
                svg {
                    animation: move-x 500ms 200ms;
                }
            }
        }

        .containerContactLeft {
            position: relative;
        }

        .containerContactRight {
            margin-top: 2rem;
            .button{
                font-size: 0.8rem;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .containerContact {
        display: flex;

        &>div {
            width: 50%;
        }
        flex-direction: row;
    }
   
    .supportItem {
        max-width: 280px;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .sectionContainer {
        max-width: 1050px;
    }
    .containerContact{
        .containerContactRight {
            margin-top: 0px;
            display: flex ;
            justify-content: flex-end;
            .container{
                max-width: 453px;
            }
        }
    }
    .noteContainer{
        display: block;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}