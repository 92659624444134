@use "../../../assets/styles/custom" as config;
@use "sass:map";




@media screen {
    .footer_no_bg {
        background-color: transparent;
    }

    ._footer_gradient_colored {
        color: #fff;
        background: linear-gradient(180deg, #008080 -27.1%, #23253D 167.39%); //var(--izi-primary-gradient-color);
    }

    ._footer_main_colored {
        background: #008080;
        color: #fff;
    }

    ._footer {
        margin-top: 10rem;
        padding: 3rem 0px 0px 0px;


        display: block;

        &.rounded {
            border-radius: 10px 10px 0px 0px;
        }
    }

    .socialIcons {
        display: flex;

        &>* {
            margin-right: 10%;
        }
    }

    ._logo {
        width: 190px;
        margin-bottom: 20px;
    }

    ._footer_section {
        //margin: 30px;
        //padding: 20px;
        width: 100%;

        .contact_section {
            margin-top: 0.7rem;
        }

        h6 {
            font-family: "Typold Bold";
            font-size: 1.1rem;
        }

        .btsl {
            margin-bottom: 0.8rem;
        }
    }

    a {
        text-decoration: none;
    }

    .footerSp {
        text-align: left;
        font-size: 0.85rem;
        font-family: "Typold Light";
        margin: 2px 0px;
        color: #fff !important;
        text-decoration: none;
        display: flex;
        align-items: center;
        width: 100%;
    }

    ._footer_link {
        text-align: left;
        font-size: 0.85rem;
        font-family: "Typold Light";
        margin: 20px 0px;
        color: #fff !important;
        text-decoration: none;
        display: flex;
        align-items: center;
        width: 100%;

        &:hover {
            //color:var(--izi-primary-color) !important;
        }

        svg {
            width: 1.2rem;

        }
    }

    ._icon {
        margin-right: 10px;
    }

    ._icon_rounded {
        padding: 2px 4px;
        border: 1px solid #fff;
        border-radius: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 12px;
        }
    }

    ._footer_icons_section {
        &>div:last-child {
            display: inline-flex;
        }
    }

    .container {
        max-width: 1300px;
        padding: 0px 0.8rem;
        margin: 0px auto;
        width: 100%;
    } 

    ._copywrite_section {
        padding: 1rem 0px;
        margin: 1rem 0px 0px 0px;
        font-size: 0.8rem;
        display: flex;
        border-top: 1px solid #ffffff14;
        font-family: "Typold Thin";
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .flex {
            display: flex;
        }

        .align_items_center {
            align-items: center;
        }

        p {
            span.__text{
                display: none;
            }
        }
    }

    ._copywrite_section a p {
        margin: 0px 0.5rem;
    }

    ._footer_links {
        justify-content: space-around;
        flex-wrap: wrap;

        &>div {
            width: 100%;
        }

        svg {
            &:hover {
                path {
                    stroke: #fff;
                }
            }
        }
    }

    .basic_footer {
        p {
            font-size: 0.7rem !important;
        }

        svg {
            scale: 0.8 !important;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .container {
        max-width: map-get(config.$container-max-widths, "sm");
        padding: 0px 0.9rem;
    }
    ._footer {

        &.rounded {
            border-radius: 30px 30px 0px 0px;
        }
    }

    ._footer_links {
        justify-content: space-around;
        flex-wrap: wrap;
        display: flex;

        &>div {
            width: 50%;
            justify-content: center;
        }
    }
    ._copywrite_section {

        p {
            span.__text{
                display: inline;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .container {
        max-width: map-get(config.$container-max-widths, "md");
        padding: 0px 1.2rem;
    }
    ._footer {
        &.rounded {
            border-radius: 50px 50px 0px 0px;
        }

    }

    ._footer_links {
        &>div {
            width: calc(100% / 3);
            padding-left: 2rem;
        }

    }

    ._copywrite_section {
        justify-content: space-between;
    }
    

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .container {
        max-width: map-get(config.$container-max-widths, "lg");

    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .container {
        max-width: map-get(config.$container-max-widths, "xl");
    }
    ._footer_links {
        &>div {
            width: calc(100% / 6);
            flex: 1;
            //padding-left: 2rem;
        }

        &>div:first-child {
            padding-left: 0px;
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {
    .container {
        max-width: map-get(config.$container-max-widths, "2xl");
    }
}