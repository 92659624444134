@use "../../../assets/styles/custom" as config;
@use "../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {
    .container_content {
        margin: 0px auto;
    }
    .conditions {
        padding: 5px;
        
        li,
        button {
            cursor: pointer !important;
        }

        padding: 0rem 1rem;
        margin-top: 2rem;

        h2 {
            font-size: 1.3rem;
            font-family: "Typold Bold";
        }

        

        p,
        li, td {
            font-size: 0.9rem;
            line-height: 1.2rem;

            &.text-lighten {
                font-family: "Typold Light";
                font-size: 1rem;
            }
        }

        ul {
            margin-left: 0.6rem;
            li {
                display: block;
                position: relative;
                align-items: center;
                padding-left: 10px;
                margin-top: 0.5rem;
                &::before {
                    left: 0;
                    position: absolute;
                    top: 7px;
                    flex-shrink: 0;
                    content: "";
                    display: block;
                    width: 5px;
                    height: 5px;
                    background-color: #000;
                    border-radius: 5px;
                    margin-right: 0.7rem;
                }
            }
        }

        h3 {
            font-size: 1.2em;
            font-family: "Typold Regular";
        }

        .listTerms {
            padding: 1.5rem;
            padding-bottom: 2rem;
            padding-top: 2rem;
            border: 2px solid var(--izi-primary-color);
            border-radius: 1rem;
        }

        h5 {
            font-size: 1.1rem;

            &.titleHighlited {
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    margin-right: 1rem;
                    display: block;
                    width: 5px;
                    height: 22px;
                    background-color: var(--izi-primary-color);
                }
            }
        }

        h6, th {
            font-size: 0.9rem;
        }

        .listTerms {
            ul {
                margin-top: 3rem;
                display: flex;
                flex-wrap: wrap;

                li {
                    font-size: 1rem;
                    margin-top: 1rem;
                    width: 100%;
                    position: relative;
                    display: flex;
                    align-items: center;

                    &::before {
                        margin-right: 0.8rem;
                        content: "";
                        display: block;
                        width: 5px;
                        height: 5px;
                        border-radius: 5px;
                        background-color: var(--izi-primary-color);
                    }
                }
            }

        }
        table{
            margin-top: 3rem;
            border: 1px solid ;
            border-collapse: collapse;
            &.grouped{
                text-align: left;
                th,td{
                    padding: 1rem;
                }
                th{
                    
                    background-color: var(--izi-primary-color);
                    color:#fff;
                }
            }
        }

        .downloadButton {
            background-color: #008080;
            color: #fff;
            border: none;
            border-radius: 6px;
            padding: 10px 20px;
            cursor: pointer;
            font-size: 1rem;
            transition: background-color 0.3s ease;
            margin-top: 15px;
            font-family: 'Typold Regular';
          
            &:hover {
              background-color: #00808021;
              color: #000;
              transition: 0.8s ease-out;
            }
          }
        

        .app {
            display: flex;
            font-family: Arial, sans-serif;
            margin-top: 3rem;
            font-family: 'Typold Regular';
            font-size: 1rem;
            flex-direction: column; /* Mettez la direction en colonne pour les écrans mobiles */
            
            @media (min-width: 768px) {
                flex-direction: row;
                background: 'red';
            }
            

            .sidebar {
                flex: 0 0 20%;  
                background-color: #f1f1f1;
                padding: 20px;
                height: 700px;
                transition: transform 0.3s ease; 
                transform: translateX(0); 
                flex-direction: column;
                overflow-y: scroll;
                scrollbar-width: thin; 
                font-size: 0.8rem;
                scrollbar-color: #ccc #00808021; /* Couleurs de la barre de défilement et du bouton */

                /* Styles pour les navigateurs WebKit (Chrome, Safari) */
                &::-webkit-scrollbar {
                    width: 3px; /* Largeur de la barre de défilement */
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #008080; /* Couleur du bouton de défilement */
                }

                &::-webkit-scrollbar-thumb:hover {
                    background-color: #999; /* Couleur du bouton de défilement au survol */
                    width: 15px;
                }
                
                
                @media (max-width: 768px) {
                    // transform: translateX(-100%);
                    display: none !important;
                }
                
              
                ul {
                  list-style: none;
                  padding: 0;
              
                  li {
                    margin-bottom: 10px;
                    cursor: pointer;
                  }
                }

                div {
                    margin-top: 1.2rem;
                    font-weight: 700;
                }
            }

            .content {
                flex: 1;
                padding: 20px;
                // overflow-y: scroll;
                transition: flex 0.3s ease;

                .display {
                    margin-left: 1.7rem;

                    @media (max-width: 768px) {
                        display: none !important;
                    }
                }

                .subcontentStyle {
                    padding: 0rem 1.8rem;
                    margin-top: 2rem;

                    .section {
                        margin-bottom: 40px;
                        padding:10px;
                    
                        h2 {
                          margin-bottom: 10px;
                        }
                      }
                }

                @media (max-width: 768px) {
                    .display {
                        margin-left: 1rem;
                    }
                }
            }
          
            
        }


        .app .sidebar.open {
            transform: translateX(-100%);
        }
        
        .app .content.open {
            margin-left: 0;
        }

        .app .content .open {
            margin-left: 200px;
        }
    }



    .modal {
        max-width: 1000px !important;
        padding: 2rem;
        overflow: auto !important;
        height: 100%;
        .headerZone {
            display: flex;
            justify-content: flex-end;
        }

        .closeBtn {
            padding: 0.3rem;
            border: none;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 1rem;
            color: #fff;
            background-color: var(--izi-primary-color);
        }
        .content{
            margin-bottom: 1rem;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .container_content {
        max-width: map-get(config.$container-max-widths, "sm");
        padding: 0px 0.9rem;
    }
    .conditions {
        padding: 5px;
        h2 {
            font-size: 1.3rem;
        }

        h3 {
            font-size: 1.2rem;
        }

        h5 {
            font-size: 1.1rem;
        }

        .listTerms {
            ul {
                li {
                    z-index: 0;
                    width: 50%;
                }
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .container_content {
        max-width: map-get(config.$container-max-widths, "md");
        padding: 0px 0.9rem;
    }
    .conditions {
        // margin-top: 3rem;
        // padding: 5px;

        h2 {
            font-size: 1.3rem;
        }

        h3 {
            font-size: 1.2em;
        }

        h5 {
            font-size: 1rem;
        }

    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .container_content {
        max-width: map-get(config.$container-max-widths, "lg");
        padding: 0px 0.9rem;
    }
    .conditions {
        h2 {
            font-size: 1.8rem;

        }

        h3 {
            font-size: 1.5em;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .container_content {
        max-width: 1060px;
        padding: 0px 0.9rem;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {
    .container_content {

        padding: 0px 0.9rem;
    }
}