@use "../../../assets/styles/custom" as config;
@use "sass:map";



@media screen {
    .headerContainer {
        padding-top: 63px;

        button {
            box-shadow: none !important;
        };
        
    }

    .qrCodeContainer {
        cursor: pointer;
        .qrCode {
            max-width: 150px;
            max-height: 150px;
        }
        p{
            margin-top: 0.5rem;
            font-size: 0.65rem;
            text-align: center;
            font-family: "Typold Book";
        }
    }

    .suppr {
        display: none;
    }

    #headerStyleCafe {
        background-color: "green" !important;
    }

    .header {
        button {
            font-family: "Typold Regular" !important;
            font-size: 0.7rem;
        }

        position: fixed;
        width: 100%;
        z-index: 10;
        top:0px;
        left: 0px;
        right:0px;
        border-radius: 0px 0px 20px 20px;

        .overlayMenu {
            display: none;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        &.fixed {
            height: 100vh;
            background: #fff;

            .overlayMenu {
                display: block;
                height: 100%;
                overflow-y: auto;
            }
        }
    }

    .headerContentContainer {

        backdrop-filter: blur(7.5px);
        border-radius: 0px 0px 20px 20px;
        background: var(--izi-header-bg-color);
    }

    /* darkModeStyles.css */
    .darkModeHeader {
        background: rgba(22, 23, 36) !important;
        color: #fff !important;
        border-radius: 0px !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
        border-bottom-left-radius: 9px !important;
        border-bottom-right-radius: 9px !important;
        backdrop-filter: none !important;
    }

    .darkModeHeaderMenu {
       /*  background: rgba(22, 23, 36) !important; */
        color: #fff !important;
    }


    .headerContent {
        display: flex;
        align-items: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        button.primary-focus {

            &:focus,
            &:hover {
                color: var(--izi-primary-color);

                path {
                    stroke: var(--izi-primary-color);

                }
            }

            span {
                margin-left: 0.25rem;
            }

            &.active {
                color: var(--izi-primary-color);

                path {
                    stroke: var(--izi-primary-color);

                }

                .sublinkbox {
                    display: block;
                }
            }

            .sublinkbox {
                display: none;
            }

        }

        .btnContainer,
        .righButtonsContainer {
            display: flex;
            align-items: center;

        }

        .btnContainer {
            width: 100%;
            justify-content: flex-end;

            .mobile-menu-flag {
                margin-left: 1rem;
                padding-top: 0;
                padding-bottom: 0;
                padding-right: 0;
                padding-left: 0;
            }

            a {
                padding: 0.5rem;
            }
        }

        .leftButtonsContainer {
            padding-left: 20px;
        }
    }

    .right-menu-buttons,
    .leftButtonsContainer {
        display: none;
    }

    .btn-link {
        display: block;
        padding: 0.5rem 0.8rem;
        text-decoration: none;
        font-size: 1rem;
        color: var(--izi-primary-dark-color)
    }

    .link {
        position: relative;

        &.active,
        &:hover {
            color: var(--izi-primary-color);

            .sublinkbox {
                display: block;
                opacity: 1;
            }
        }

        &.inactive {
            .sublinkbox {
                display: none;
                opacity: 0;
            }
        }

        .sublinkbox {
            transition: opacity 200ms;
            opacity: 0;
            display: none;
            z-index: 1;
            position: absolute;
            padding: 1rem;
            filter: drop-shadow(2px 5px 5px rgba(0, 0, 0, 0.274));
            border-radius: 10px;
            background-color: #fff;
            right: 0px;

            button {
                width: 100%;
                background: transparent;
                color: var(--izi-primary-dark-color);
                text-align: left;
                min-width: 120px;
                font-size: 0.8rem;

                &:hover {
                    color: var(--izi-button-color);
                    background: transparent;
                }

                &:hover {
                    color: var(--izi-button-color);
                    background: transparent;
                }
            }
        }
    }

    .submenubutton {

        &.open {
            .submenu {
                z-index: 10;
                opacity: 1;
                max-height: 100%;
                overflow: auto;
            }
        }

        .submenu {
            opacity: 0;
            max-height: 0px;
            overflow: hidden;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {

    .headerContent {
        .btnContainer {
            justify-content: space-between;
        }

        .right-menu-buttons,
        .leftButtonsContainer {
            display: flex;
        }

        .mobile-menu-flag {
            display: none;
        }

    }

    .btn-link {
        color: var(--izi-primary-dark-color);
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .header {
        button {
            font-family: "Typold Regular" !important;
            font-size: 0.8rem;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}


/*

*/