@import "./custom";
@import "./custom_animations";


* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    transition: all 200ms;
}

ul li{
    list-style: none;
}

body {
    font-family: var(--izi-font-familly);
    font-size:var(--izi-font-size-p);
    min-width: 300px;
    overflow-x: hidden;
    color: var(--izi-primary-dark-color);
    font-display: swap;
}
#root{
    overflow-x: hidden;
}



