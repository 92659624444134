

:root {
    --animation-duration: 2900ms;
    --animation-delay: 1s
}

svg {
    position: relative;
    overflow: overlay;
}

/* Glob path */
.africa-line,
.africa-line-border {
    stroke-dasharray: 1;
    stroke-dashoffset: 1;

}

.draw .africa-line,
.draw .africa-line-border {
    animation: path-drawer-animation var(--animation-duration) var(--animation-delay) forwards;
}


/* Dots path */
.africa-dots path.africa-dot:nth-child(1),
.africa-dots path.africa-dot:nth-child(2),
.africa-dots path.africa-dot:nth-child(3),
.africa-dots path.africa-dot:nth-child(4),
.africa-dots path.africa-dot:nth-child(5),
.africa-dots path.africa-dot:nth-child(6),
.africa-dots path.africa-dot:nth-child(7),
.africa-dots path.africa-dot:nth-child(8),
.africa-dots path.africa-dot:nth-child(9),
.africa-dots path.africa-dot:nth-child(10),
.africa-dots path.africa-dot:nth-child(11),
.africa-dots path.africa-dot:nth-child(12),
.africa-dots path.africa-dot:nth-child(13),
.africa-dots path.africa-dot:nth-child(14),
.africa-dots path.africa-dot:nth-child(15) {
    opacity: 0;
}

.draw .africa-dots path.africa-dot:nth-child(1) {
    animation: fade-in 200ms calc(200ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(2) {
    animation: fade-in 200ms calc(350ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(3) {
    animation: fade-in 200ms calc(550ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(4) {
    animation: fade-in 200ms calc(650ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(5) {
    animation: fade-in 200ms calc(700ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(6) {
    animation: fade-in 200ms calc(850ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(7) {
    animation: fade-in 200ms calc(900ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(8) {
    animation: fade-in 200ms calc(1000ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(9) {
    animation: fade-in 200ms calc(1050ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(10) {
    animation: fade-in 200ms calc(1250ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(11) {
    animation: fade-in 200ms calc(1450ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(12) {
    animation: fade-in 200ms calc(1600ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(13) {
    animation: fade-in 200ms calc(1900ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(14) {
    animation: fade-in 200ms calc(2100ms + var(--animation-delay)) forwards;
}

.draw .africa-dots path.africa-dot:nth-child(15) {
    animation: fade-in 200ms calc(2700ms + var(--animation-delay)) forwards;
}

/* Animations */
@keyframes path-drawer-animation {
    from {

        stroke-dashoffset: 1;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes bounce-out {
    0% {
        transform: scale(0.2);
    }

    80% {
        transform: scale(1.5);
    }

    80% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes turn-sphere {
    from {
        opacity: 1;
    }

    to {
        transform: rotateZ(1turn);
    }
}



.sphere-container {
    position: relative;
}

.sphere {
    position: absolute;
}

.start-sphere,
.end-sphere {
    position: absolute;
    transform-origin: center;
    animation: turn-sphere 30s linear infinite forwards;
}


/* Globe Animation */
.globe-container {
    position: relative;
    width: 130%;
    height: 130%;
    position: relative;
}

.globe-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    background-color: #008080;
    position: relative;
}

.globe-wrapper img {
    height: 100%;
}

.globe-wrapper .img {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    animation: slide 12s linear infinite;
}

.globe-wrapper .img>* {
    flex-shrink: 0;
}

.globe-wrapper .img img:last-child {
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;

}

.globe-border {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-51%, -50%);
    width: 155%;
    height: 155%;
    z-index: 0;
}



@keyframes slide {
    to {
        transform: translateX(-100%);
    }
}

@keyframes path-drawer-animation-globe {
    0% {

        stroke-dashoffset: 1;
    }

    25% {
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dashoffset: -1;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

.globe-border path {
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
}

.globe-border>path:nth-child(1) {
    animation: path-drawer-animation-globe 3s linear 0s infinite forwards;
    stroke: #00abab;

}

.globe-border>path:nth-child(2) {
    animation: path-drawer-animation-globe 3s linear 500ms infinite forwards;
    stroke: #fb9129;
}

.globe-border>path:nth-child(3) {
    animation: path-drawer-animation-globe 3s linear 1000ms infinite forwards;
    stroke: #6672c7;
}

.globe-border>path:nth-child(4) {
    animation: path-drawer-animation-globe 3s linear 1500ms infinite forwards;
    stroke: #eb7da7;
}

/*Global Styles*/

/*
    sm: 600px,
    md: 720px,
    lg: 960px,
    xl:1220px,
    "2xl":1400px
*/
.animation-container{
    position: relative;
}
@media screen and (min-width: 600px) {
    .animation-container {
        width: 500px;
        height: 500px;
    }
}

@media screen and (min-width: 720px) {
    
}

@media screen and (min-width: 960px) {
    
}

@media screen and (min-width: 1220px) {
    .animation-container {
        width: 600px;
        height: 600px;
    }
}

@media screen and (min-width: 1400px) {}



.africa-map-container,
.africa-map-container svg,
.globe-base-wrapper,
.globe-container {
    width: 100%;
    height: 100%;
}

.scale-fade-hide {
    opacity: 1;
    animation: scale-fade-in--hide 500ms forwards;
}

.scale-fade-show {
    opacity: 0;
    animation: scale-fade-out--show 500ms forwards;
}

.globe-base-wrapper,
.africa-map-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.globe-container {
    width: 80%;
    height: 80%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.globe-base-wrapper {
    opacity: 0;
}

@keyframes scale-in {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    40% {
        transform: scale(1);
        opacity: 1;
    }

    60% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(0);
        opacity: 0;
    }
}

@keyframes scale-fade-in--hide {
    0% {}

    1% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0.7);
        opacity: 0;

    }
}

@keyframes scale-fade-out--show {

    0% {}

    1% {
        transform: scale(0.7);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;

    }
}



/*Animated transactions*/

@keyframes path-drawer-animation-right {
    from {

        stroke-dashoffset: -1;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes fade {


    to {
        opacity: 1;
    }
}

.__animation_transaction {
    padding:0.5rem 0.75rem  ;
    background: #FFFFFF;
    box-shadow: 0px 30px 100px 35px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    max-width: fit-content;
}

.__animation_transaction .__animation_transaction_user_image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.__animation_transaction .__animation_transaction_details_container {
    padding-left: 1rem;

}

.__animation_transaction .__animation_transaction_details_container .__animation_transaction_details_fiat_img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 0.5rem;
}

.__animation_transaction .__animation_transaction_state_container {
    display: flex;
    align-items: center;
    margin-top: -0.3rem;
}

.__animation_transaction .__animation_transaction_state_container span {
    margin-left: 0.5rem;
}

.__animation_transaction .__animation_transaction_details_container h6{
    font-size: 0.9rem !important;
}
.__animation_transaction .__animation_transaction_state_container p {
    font-size: 0.6rem;
}

/*First bloc*/


.move.__animation_transaction_container > .__animation_transaction:nth-child(1){
    
    left: 0%;
}
.move.__animation_transaction_container > .__animation_transaction:nth-child(2){
    top: 15%;
    right: 30%;
    
}
.move.__animation_transaction_container > .__animation_transaction:nth-child(3){
    bottom: 15%;
    left: 35%;
    
}
.move.__animation_transaction_container > .__animation_transaction:nth-child(4){
    
}
.move .__animation_transaction_arrow_left{
    top:22%;
    left: 20%;
}

.move .__animation_transaction_arrow_right{
    bottom: 25%;
    right: 20%;
    
}

.move.__animation_transaction_container .__animation_transaction_arrow_left path:first-child, .move.__animation_transaction_container .__animation_transaction_arrow_right path:first-child{
    stroke: #fff;
}

.move.__animation_transaction_container .__animation_transaction_arrow_left path:last-child, .move.__animation_transaction_container .__animation_transaction_arrow_right path:last-child{
    stroke: #fff;
}

.__animation_transaction_container > .__animation_transaction{
    transition: all 500ms;
}

.__animation_transaction_container > .__animation_transaction:nth-child(1){
    position: absolute;
    top:40%;
    left: 20%;
    animation: scale-fade 500ms var(--animation-duration) forwards;
    opacity: 0;
}


.__animation_transaction_container > .__animation_transaction:nth-child(2){
    position: absolute;
    top:20%;
    right: 20%;
    opacity: 0;
    animation: scale-fade 500ms  calc(var(--animation-duration) + 500ms) forwards;
}
.__animation_transaction_arrow_left path:first-child{
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
    animation: path-drawer-animation-right 500ms calc(var(--animation-duration) + 1000ms) forwards;
}

.__animation_transaction_arrow_left path:last-child{
    opacity: 0;
    animation: fade 500ms calc(var(--animation-duration) + 1500ms) forwards;
}
/*Second bloc*/

.__animation_transaction_container > .__animation_transaction:nth-child(3){
    position: absolute;
    bottom:27%;
    left: 27%;
    opacity: 0;
    animation: scale-fade 500ms  calc(var(--animation-duration) + 2000ms) forwards;
}
.__animation_transaction_container > .__animation_transaction:nth-child(4){
    position: absolute;
    bottom:41%;
    right: 0%;
    opacity: 0;
    animation: scale-fade 500ms  calc(var(--animation-duration) + 2500ms) forwards;
}

.__animation_transaction_arrow_right path:first-child{
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
    animation: path-drawer-animation-right 500ms calc(var(--animation-duration) + 3000ms) forwards;
}

.__animation_transaction_arrow_right path:last-child{
    opacity: 0;
    animation: fade 500ms calc(var(--animation-duration) + 3500ms) forwards;
}


/*end animation*/


.__animation_transaction_arrow_left{
    position: absolute;
    top:22%;
    left: 30%;
    
}
.__animation_transaction_arrow_left svg{
    width:100px;
}

.__animation_transaction_arrow_right{
    position: absolute;
    bottom: 25%;
    right: 27%;

}
.__animation_transaction_arrow_right svg{
    width:80px;
}
.__animation_wrapper{
    position: relative;
}



@keyframes scale-fade {
    0%{
        opacity: 0;
        transform: scale(0.8);
    }
    40%{
        opacity: 1;
        transform: scale(1.1);
    }
    60%{
        opacity: 1;
        transform: scale(0.9);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}