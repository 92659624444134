@use "../../../assets/styles/custom" as config;
@use "../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {
    .formContainer {
        padding: 2rem 0px;
        display: flex;
        flex-direction: column;

        .imgContainer {
            max-width: 650px;
            max-height: 300px;
            
            overflow: hidden;
            border-radius: 30px;
            width: 100%;
        }

        .formContainerFormContainer {
            width: 100%;
            margin: 4rem 0px;
            
        }

        

        img {
            width: 100%;
            object-fit: cover;
        }
        .formContainerForm {
            width: 100%;
            margin-top: 1.5rem;
            padding: 0px;
        }
        .formFieldContainer > .formContainerForm:first-child{
            margin-top: 0;
        }
        .formContainerFormContainer {

            .form {
                max-width: 400px;
                width: 100%;
                margin: 0px auto;

                h2 {
                    font-family: "Typold Regular";
                }
            }
        }

        .footerBtns {
            display: flex;
            font-size: 1.2rem;
            margin-top: 2rem;

            button {
                font-family: "Typold Light";
                padding: 0.9rem 1.5rem;
                width: 100%;

            }

            &>div:first-child {
                width: 40%;
                padding-right: 0.25rem;
            }

            &>div:last-child {
                width: 60%;
                padding-left: 0.25rem;
            }
        }

        .formFieldContainer {
            margin-top: 2.7rem;
        }

    }


}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .formContainer {
        flex-direction: row;

        .imgContainer {
            max-width: 650px;
            max-height: 100%;
            width: 50%;
        }

        .formContainerFormContainer {
            width: 50%;
            margin: 0px;
        }
        .form {     
            margin-left: auto;
            padding: 0px 20px;
            margin-top: 1rem;
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .formContainer {
        .imgContainer {
            width: 60%;
        }

        .formContainerFormContainer {
            width: 40%;
        }

        .formContainerForm {
            .form {

                h2 {
                    font-size: 1.7rem;
                }
            }
        }

    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}