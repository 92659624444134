@use "sass:map";

:root {
    
     //old 
     --color-primary-light: #F4FFFA;
     --color-primary-light-secondary: #DEF4F4;
     --color-primary-light-ternary:#E8EFFF;
     --color-primary-blue:#3257A8;
     --color-primary-light-border:#CBEBDC;
     --color-primary-gradient: linear-gradient(125.75deg, #008080 -8.29%, #23253D 281.64%);
     --color-primary-gradient-horizontal: linear-gradient(180deg, #008080 -27.1%, #23253D 167.39%);
     --color-primary: #008080;
     --color-dark: #151515;
     --color-blue-dark:#2F2E41;
     --color-white: #fff;
     --color-danger-light: #FFEAEC;
     --color-danger: #DC3F4D;
     --color-danger-lighten: #FFE7E9;
     --color-dark-light:#333333;
    //new
    --izi-font-familly: 'Typold Regular', Arial, Helvetica, sans-serif;
    --izi-bg-color: #F4FFFA;
    --izi-bg-empty-bar:rgba(0, 128, 129, 0.2);
    --izi-primary-gradient-color: linear-gradient(125.75deg, #008080 -8.29%, #23253D 281.64%);
    --izi-primary-color: #008080;
    --izi-primary-dark-color: #333333;
    --izi-primary-danger-color:#ef4444;
   --izi-header-bg-color: rgba(244, 255, 250, 0.8); 
 /*   --izi-header-bg-color: #333333; */
    --izi-table-header:#686666;


   

    //font sizes
    --izi-font-size: 14px;
    --izi-font-size-h1: 3rem;
    --izi-font-size-h2: 2.25rem;
    --izi-font-size-h3: 1.875rem;
    --izi-font-size-h4: 1.5rem;
    --izi-font-size-h5: 1.25rem;
    --izi-font-size-h6: 1.125rem;
    --izi-font-size-p: 1rem;
    --izi-font-size-subtitle:0.9rem;
    --izi-font-size-th:0.8rem;

    
}

$grid-breakpoints: (
    xs: 0,
    sm: 640px,
    md:768px,
    lg:1024px,
    xl:1300px,
    "2xl":1536px
);

$container-max-widths: (
    sm: 600px,
    md: 720px,
    lg: 960px,
    xl:1180px,
    "2xl":1400px
);






/*
*/