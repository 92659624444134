@use "../../../../assets/styles/custom" as config;
@use "sass:map";

.testimonialContainer {
    --image-size:70px;
    --testimonial-width:380px;
    
}
.testimonial{
    --izi-testimonial-color: var(--color-primary);
    --izi-bg-color: var(--color-primary);
}


@media screen {
    .testimonialContainer {
        margin-top: 4rem;
        .testimonial {
            position: relative;
            max-width: var(--testimonial-width);
            padding-top: calc( var( --image-size) / 2 );
            .imageContainer {
                position: absolute;
                top: 0;
                padding: 0px 1.5rem ;
                width: 100%;
                img {
                    width: var( --image-size);
                    height: var( --image-size);
                    border-radius: 100%;
                    box-shadow: 0px 4px 50px 0px #00000040;
                }
            }

            .contentContainer {
                background-color: var(--izi-bg-color);
                border-radius: 20px;
                padding: 1.8rem;
                padding-bottom: 1.5rem;
                h6 {
                    margin-top: 1rem;
                    line-height: 1.3rem;
                    font-weight: 400;
                    font-size: 0.9rem;
                }


                // .BlocprofessionStyle {
                //     margin-top: 0.8rem;
                    
                //     .professionStyle {
                //         font-size: 0.4rem;
                //     }
                // }

            }
            .testimonialFooter{
                display: flex;
                margin-top: 1rem;
                align-items: flex-end;
                p{
                    font-family: "Typold Medium";
                    color: var(--izi-testimonial-color) !important;
                }
                p:first-child{
                    font-size: 0.9rem;
                   
                    
                }
                p:last-child{
                    font-size: 0.7rem;
                }
                span{
                    position: relative;
                    width: 10px;
                    height: 8px;
                    margin: 0px  0.25rem;
                    &::after{
                        content: "";
                        position: absolute;
                        height: 1.5px;
                        width: 10px;
                        top:0px;
                        background-color: var(--izi-testimonial-color);
                    }
                }
            }
        }
        .testimonialsWrapper{
            display: flex;
            flex-wrap: wrap;
            &>div{
                width: 100%;
                padding: 2rem;
                display: flex;
                justify-content: center;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .testimonialContainer{
        .testimonial{
            width:var(--testimonial-width);
            .contentContainer{
                padding-left: 4rem;
            }
        }
        .testimonialsWrapper{
            padding:  0px 3rem;
            &>div{
                width: 50%;
                justify-content: flex-start;
            }
           
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    
    
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .testimonialContainer{
        

        .testimonialsWrapper{
            max-width: 1200px;
            margin: 0px auto;
            &>div:nth-child(1){
                padding-left: 5%;
            }
            &>div:nth-child(3), &>div:nth-child(4){
                
                .testimonial{
                    flex-shrink: 0;
                }
            }
            &>div:nth-child(3){
                padding-left: 12%;
            }
            &>div:nth-child(4){
                padding-left: 10%;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}