@use "../../../../assets/styles/custom" as config;
@use "sass:map";



@media screen {
    input[type="password"]{
        
    }
    .formContainer {
        //height: 100vh;
        h3 {
            font-size: 1.5
            rem;
        }

        padding-top: 3rem;
        padding-bottom: 1rem;
        max-width: 400px !important;
    }

    .acceptConditions {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 14px;

        .labCheck {
            margin-left: 10px;

            a {
                color: var(--izi-primary-color);
                text-decoration: none;
                text-align: left;
                font-size: 0.8rem !important; 
            }
        }
    }

    .form {
        padding-top: 1rem;


        input {
            font-family: "Typold Regular";
            &::placeholder {
                font-size: 0.9rem;
            }
        }

        >div {
            margin-top: 1.5rem;

            input[type="checkbox"] {
                margin-right:5px;
            }
        }

        
    }
    .option{
        display: inline-flex;
        align-items: center;
        img{
            width: 1.3rem;
            margin-right: 1rem;
        }

    }

    .errorMsg {
        color: red;
        font-size:14px;
        margin-top: 10px;
    }

    .a {
        color: var(--izi-primary-color) !important;
        text-decoration: none;
        text-align: left;
        font-size: 0.8rem !important;
    }

    .mdpForgotten {
        display: flex;
        justify-content: right;
    }

    .ctaButton {
        width: 100%;
        margin-top: 2rem;
        font-size: 1rem !important;
        padding: 0.7rem;
    }

    .endornment {
        padding: 0px 0.7rem;
        position: relative;
    }

    .ctaRegister {
         a {
            font-size: 0.75rem !important;

            span:first-child {
                color: var(--izi-primary-dark-color) !important;
            }

            span {

                &.bolded {
                    font-family: "Typold Bold";
                    color: var(--izi-primary-color) !important;
                }
            }

        }
    }
    .validations{

        .validationList{
            font-size: 0.8rem;
            margin-top: 0.5rem;
            display: flex;
            align-items: center;
            list-style: none;
            .valid{
                padding: 2px;
                border-radius: 50%;
                color:#fff ;
                background: var(--color-primary);
                display: inline-flex;
                margin-right: 0.5rem;
            }
            .notvalid{
                padding: 2px;
                border-radius: 50%;
                color:#fff ;
                background: var(--color-danger);
                display: inline-flex;
                margin-right: 0.5rem;
            }
        }
    }
    .errorText{
        margin-top: 0.5rem;
        font-size: 0.8rem;
        color:var(--izi-primary-danger-color);
    }
    .adornmentGroup{
        display: inline-flex;
        align-items: center;
        & *{
            transition: color 100ms ;
        }
        .showPassword{
            font-size: 1.2rem;
            padding: 0px 10px;
            color: var(--color-dark);
            color: var(--color-primary);
            
            &:hover{
                color: var(--color-primary-dark);
            }
        }
    }
}

.alert{
    padding: 20px  ;
    border-radius: 20px;
    align-items: center;
    display: flex;
    p{
        font-size: .85rem;
    }
    .btnContainer{
        padding-left: 1rem;
    }
    &.danger{
        background-color: var(--color-danger-light);
        color:var(--color-danger);
    }
    &.success{
        background-color: var(--color-primary-light);
        color:var(--color-primary);
    }
   
    .btn{
        padding: 5px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.034) ;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
        &:hover{
            background-color: rgba(0, 0, 0, 0.082);
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .formContainer {
        max-width: 320px !important;
        //margin-top: 4rem;
        //margin-bottom: 4rem;
        h3 {
            font-size: 1.7rem;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}