@use "../../../../assets/styles/custom" as config;
@use "../../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {
    
    .conditionContainer{
        &>.hideMd{
            text-align: center;
            img{
                margin-top: 3rem;
                width: 100%;
                max-width: 200px;
            }
        }
        margin-top:5rem;
        h3{
            font-size: 1.2rem;
            text-align: center;
        }
        .conditions{
           
            margin-top: 3rem;
            display: flex;
            flex-direction: column;
            &>div:first-child{
                &>div{
                    display: flex;
                    &:nth-child(1){
                        justify-content: center;
                    }
                    &:nth-child(3){
                        justify-content: center;
                    }
                }
            }
            &>div:last-child{
                &>div{
                    display: flex;
                    &:nth-child(1){
                        justify-content: center;
                    }
                    &:nth-child(3){
                        justify-content: center;
                    }
                }
            }
        }
    }
    .condition{
        margin-bottom: 2rem;
        max-width: 330px;
        font-size: 0.9rem;
        font-family: "Typold Light";
        p{
            padding-left: 30px;
            padding-top: 1rem;
            font-size: 1.1rem;
        }
        
        &>div{
            --izi-background-color:var(--color-primary-light-ternary);
        --izi-color: var(--color-primary-blue);
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;  
            font-family: "Typold Medium";
            font-size: 1.2rem;  
            background-color: var(--izi-background-color);
            color: var(--izi-color);
        }
    }
    .showMd{
        display: none !important;
    }


}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .conditionContainer{
        h3{
            font-size: 1.5rem;
        }
        img{
            max-width: 250px;
            width: 250px;;
        }
    }
    
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .conditionContainer{
        img{
            max-width: 280px;
            width: 280px;;
        }
        h3{
            font-size: 1.7rem;
        }
        .condition{
            margin-bottom: 0px;
        }
        .conditions{
            flex-direction: row;
            justify-content: center;
            &>div:first-child, &>div:last-child{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
            &>div:first-child{
                &>div{
                    display: flex;
                    &:nth-child(1){
                        justify-content: flex-start;
                    }
                    &:nth-child(2){
                        justify-content: flex-end;
                    }
                }
            }
            &>div:last-child{
                &>div{
                    display: flex;
                    &:nth-child(2){
                        justify-content: flex-end;
                    }
                    &:nth-child(3){
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
   
    .showMd{
        display: flex !important;
    }
    .hideMd{
        display: none !important;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .conditionContainer{
        img{
            max-width: 300px;
            width: 300px;;
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .wrapper{
        max-width: 1100px;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}