@use "../../../../assets/styles/custom" as config;
@use "../../../../assets/styles/custom_animations";
@use "sass:map";


.bannerHeader{
    border-radius: 0px 0px 30px 30px;
        background: #F6FFFB;
}

@media screen {
    .bannerContainer {
        
        padding: 3rem 0px;

        .banner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .bannerContent {

                max-width: 450px;

                h2 {
                    font-size: 2rem;
                    line-height: 1.1;
                }

                p {
                    margin: 1.5rem 0px;
                    font-size: 0.8rem;
                    line-height: 25px;
                }

                button {
                    padding: 1rem 0.75rem;
                    font-size: 0.8rem !important;

                }

            }

            .bannerImg {
                position: relative;
                display: flex;
                justify-content: center;

                .userCard {
                    position: absolute;
                    scale: 1;
                    top: 90%;
                    left: 50%;
                    transform: translateX(-50%);
                    background: #FFFFFF;
                    box-shadow: 0px 30px 100px 35px rgba(0, 0, 0, 0.1);
                    border-radius: 18px;
                    max-width: 180px;
                    padding: 18px;
                    padding-top: 30px;

                    .cardTitle {
                        display: flex;
                        align-items: center;

                        h6 {
                            font-size: 0.9rem;
                        }

                        svg {
                            scale: 0.7;
                        }

                    }

                    p {
                        font-size: 0.75rem;
                        margin-top: 0.5rem;
                    }

                    .userFlag {
                        img {
                            top: -27px;
                            left: -27px;
                            width: 55px;
                            height: 55px;
                            border-radius: 25px;
                            border: 1px solid #fff;
                            position: absolute;
                            background-color: #fff;
                        }
                    }
                }

                img {
                    &.ambassadorImg {
                        width: 100%;
                        max-width: 400px;
                    }
                }
            }


        }
    }

}



@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .bannerContainer {
        padding: 5rem 0px;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .bannerContainer {
        .banner {


            .bannerContent {

                h2 {
                    font-size: 2.5rem;

                }

                p {
                    margin: 1.8rem 0px;
                    font-size: 0.88rem;
                    line-height: 30px;
                    position: relative;

                }

                button {
                    margin-top: 1.5rem;
                    padding: 1rem 0.75rem;
                    font-size: 0.85rem !important;
                }
            }

            .bannerImg {

                .userCard {}
            }

        }


    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .bannerContainer {
        border-radius: 0px 0px 50px 50px;
        .banner {
            flex-direction: row;

            .bannerImg {
                display: block;
                margin-top: -60px;

                img {
                    &.ambassadorImg {
                        max-width: 400px;
                        width: 100%;
                    }
                }

                .userCard {
                    top: 37%;
                    left: -90px;
                    transform: translateX(0px);
                }
            }

            .bannerContent {
                h2 {
                    font-size: 3.1rem;

                }
                max-width: 400px;
            }

        }


    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .bannerContainer {
        .banner {
            .bannerImg {
                padding-right: 50px;

                .userCard {
                    //left: -90px;
                    top: 40%;
                }

                img {
                    &.ambassadorImg {
                        max-width: 430px;
                    }
                }
            }

        }


    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {
    .bannerContainer {

        .banner {

            .bannerImg {}


        }
    }
}