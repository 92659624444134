@use "./custom_animations";
._section {
    margin-top: 100px;
}

._section_title {
    color: var(--color-dark);
    font-size: 1.9rem;
    margin-top: 10px;
    font-family: "Typold Bold";
}

._section_subtitle {
    color: var(--color-primary);
    font-size: 1rem;
}

.text-center {
    text-align: center;
}

.flex {
    display: flex;
}

.align-items-end {
    align-items: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.justify-space-around {
    justify-content: space-around;
}

.ml-0 {
    margin-left: 0px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.w-full {
    width: 100%;
}

.flex-wrap {
    flex-wrap: wrap;
}

.overflow-hidden {
    overflow: hidden;
}

.align-items-center {
    align-items: center;
}


.bannerHeader{
    border-radius: 0px 0px 30px 30px;
        background: #F6FFFB;
}


.button {


    &:hover {
        span.move{
            svg {
                animation: move-x 500ms 200ms;
            }
        }
        
    }

    span.move {
        padding: 0;
        svg {
            margin-left: 0.5rem;
            width: 20px;
            padding: 0;
        }
    }
}

//xl
@media screen and (max-width: 1536px) {}

//lg
@media screen and (max-width: 1280px) {}

//md
@media screen and (max-width: 1024px) {}

//sm
@media screen and (max-width: 768px) {
    ._section_title {
        font-size: 1.5rem;
    }
    ._section_subtitle {
        font-size: 0.9rem;
    }
}

//xs
@media screen and (max-width: 640px) {
    ._section_title {
        font-size: 1.4rem;
    }
    ._section_subtitle {
        font-size: 0.85rem;
    }
}