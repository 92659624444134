
@keyframes slideTop {
    0%{
        top:var(--top);
        opacity: 0;
    }
    100%{
        top:0px;
        opacity: 1;
    }
}
.animate{
    --delay: 200ms;
    --duration: 200ms;
    --top:20px;

    position: relative;
    opacity: 0;
    top:var(--top);
    animation: slideTop var(--duration) var(--delay) forwards;
}
