@use "../../../assets/styles/custom" as config;
@use "sass:map";

@media screen{
    .logo{
        .md{
            display: none;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .logo{
        .sm{
            display: none;
        }
        .md{
            display: inline-block;
            width: 100px;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .logo .md{
        display: inline-block;
        width: 120px;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}