@use "../../../assets/styles/custom" as config;
@use "sass:map";

@media screen{
    .cta{
        &:hover{
            filter: drop-shadow(2px 10px 30px #ddd);
            transition: all 200ms;
            .containerContent {
               > div{
                    svg{
                       animation: move-x 500ms 200ms;
                    }
               }
               
           }
        }
        max-width: 450px;
        background: var(--izi-cta-bg-color);
        padding:2rem;
        border-radius: 20px;
        .headIcon{
            width: 40px;
            height: 40px;
            transition: all 100ms;
           svg{
            width: 100%;
            height: 100%;
            transition: transform 200ms 100ms;
           }
        }
        .containerContent {
             > h5{
                margin: 2rem 0px;
            }
            > p{
                margin-bottom: 1rem;
                font-size: 0.9rem;
                line-height: 1.6rem;
            }
            > div{
                display: flex;
                justify-content: flex-end;
                svg{
                    position: relative;
                    path{
                        fill: var(--izi-cta-icon-color);
                    }
                }
            }
            
            
        }
    }
    
}
@keyframes move-x { 
    0%{
        transform: translateX(0px);
    }
    40%{
        transform: translateX(-20px);
    }
    60%{
        transform: translateX(10px);
    }
    80%{
        transform: translateX(-5px);
    }
    90%{
        transform: translateX(2px);
    }
    95%{
        transform: translateX(-1px);
    }
    100%{
        transform: translateX(0px); ;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .cta{

        .headIcon{
            width: 50px;
            height: 50px;
           svg{
                
           }
        }
        .containerContent {
             > h5{
                margin: 2rem 0px;
            }
            > p{
            }
            > div{
                svg{
                    path{
                    }
                }
            }
            
        }
    }
    
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}
