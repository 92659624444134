// @use "../../../assets/styles/custom" as config;
// @use "../../../assets/styles/custom_animations";
// @use "sass:map";

// @media screen {
    .load {
        width: 100%; 
        height: 100vh; 
        //padding: 15px;
    }
    .loader {
        display: flex; 
        justify-content: center; 
        align-items: center; 
        height: 100%;
    }
   
// }