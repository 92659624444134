@use "../../../../assets/styles/custom" as config;
@use "sass:map";



@media screen {
    .container {
        display: flex;
        flex-direction: column;
        background-color: var(--color-primary);
        padding: 2.5rem 1rem 0px 1rem;
        padding-bottom: 0px;
        border-radius: 20px;
        color: #fff;

        .wrapper {
            margin-bottom: -4px;
            display: flex;
            justify-content: center;
            overflow: hidden;
        }

        p {
            color: #fff;
        }

        h3 {
            font-family: "Typold Book";
            color: #fff;
            line-height: 2rem;
            margin: 1rem 0px 1.5rem 0px;

        }

        .imgContainer {
            display: flex;
            padding: 1rem;
            justify-self: center;
            padding-top: 3rem;
            padding-bottom: 0px;
            align-items: flex-end;
            justify-content: center;
            max-width: 400px;
            max-height: 400px;

            img {
                width: 100%;
            }
            
        }

        .ctaBtnContainer {
            display: flex;
            justify-content: space-around;

            img {
                width: 100%;
                max-width: 160px;
                max-height: 45px;
                height: 100%;
            }
        }

        .descriptionContainer {}

        .qr {
            display: flex;
            justify-content: center;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .container {
        .descriptionContainer {
            padding: 2rem;
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {

    .container {
        margin: 6rem 0px;
        padding-top: 0rem;

        .ctaBtnContainer {
            justify-content: flex-start;

            img {
                max-width: 180px;
                max-height: 55px;
            }

            &>div:first-child {
                padding-right: 1rem;
            }
        }
        .wrapper {
            margin-bottom: 0;
         }
        .imgContainer {
            padding: 0px;
            position: relative;
            //bottom: -5px;
            bottom:-50px;
            .img1 {
                width: 47%;
                height: 80%;
            }
            .img2 {
                height: 100%;
            }
        }

        padding-right: 2rem;

        .descriptionContainer {
            justify-items: center;
            padding: 2rem;
        }

        &>div {
            flex: 1;
        }

        h3 {
            line-height: 2.5rem;
        }

        .qr {
            display: block;
        }
    }
}


@media screen and (min-width: map.get(config.$grid-breakpoints, "xs")) {
    .imgContainer {
        .img1 {
            width: 47%;
            height: 80%;
        }
        .img2 {
            height: 100%;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .containerWrapper {
        padding: 0px 4rem;
    }

    .container {
        h3 {
            line-height: 2.8rem;
            font-size: 1.7rem;
        }
        p{
            font-size: 1rem;
        }

        flex-direction: row;
        

        .descriptionContainer {}
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .container {
        flex-direction: row;
         
        .descriptionContainer {
            padding-top: 5rem;
            padding-right: 0px;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}