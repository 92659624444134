.bandeau {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 30%;
    background-color: #fff;
    color: #000;
    padding: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    z-index: 2;
    height: 12rem;
    border-radius: 12px;
    box-shadow: 9px 12px 42px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 9px 12px 42px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 9px 12px 42px 0px rgba(0, 0, 0, 0.16);
    margin: 1rem;

    .pp {
        font-size: 1rem;
        text-align: left;

        a {
            color: #008080;
        }
    }

    p {
        font-size: 0.8rem;
    }

    .pbtn {
        display: flex;
    }

    #accepter {
        background-color: #008080;
        color: #fff;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        font-family: "Typold Regular";

        margin-left: 1rem;
    }

    #refuser {
        background-color: #fff;
        color: #000;
        border: 1px solid red !important;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        font-family: "Typold Regular";
        margin-right: 0.2rem;
        font-size: .7rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 1297px) {
    .bandeau {
        height: 20rem;
    }

    .pbtn {
        margin-top: 0.8rem;
    }
}

@media screen and (max-width: 768px) {

    .bandeau {
        height: 10rem;
        width: 100% !important;
        margin: 0rem !important;

        .pp {
            font-size: 0.8rem !important;
        }

        p {
            font-size: 0.6rem;

        }

        .pbtn {
            margin-top: 0.5rem;
        }

        #accepter {
            font-size: 0.6rem;
        }

        #refuser {
            //    margin-top: 0.5rem;
            margin-left: 0rem;
            padding: 0.5rem 1.3rem !important;
            font-size: 0.6rem;
        }
    }
}