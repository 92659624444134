@use "../../../assets/styles/custom" as config;
@use "../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {

    .apdp {
        
        z-index: 100;
        display: flex;
        position: fixed;
        overflow-y: auto;
        overflow-x: hidden;
        inset: 0;
        // backdrop-filter: blur(5px);
        width: 100%;
        justify-content: center;
        align-items: center;
        // background-color: #b5b3b336;
        // height: 100vh;

        .bloc {
            background-color: aliceblue;
            padding: 1.5rem;
            width: 600px;
            height: 600px;
            border-radius: 12px;
            overflow-y: scroll;
            font-size: 13px;
            margin: 2rem;

            &::-webkit-scrollbar {
                width: 3px; 
            }

            &::-webkit-scrollbar-thumb {
                background-color: #008080; 
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #999; 
                width: 15px;
            }

            .title {
                font-weight: 700;
                font-size: 1rem;
            }

            .link {
                color: #008080;
            }
        }
    
      
      .modalContentShow {
        color: #000;


        .p {
            span {
                font-weight: 800;
            }
            .bloc {
                font-size: 12px;
            }
            .link {
                color: #008080;
            }
        }
      }
      
      .closeIcon {
            padding: 0.5rem 1rem;
            cursor: pointer;
            position: relative; 
            border: none;
            border-radius: 0.7rem;
            font-family: "Typold Medium";
            transition: all 100ms;
            margin-bottom: 1rem;
      }
    }

}