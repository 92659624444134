@use "../../../assets/styles/custom" as config;
@use "../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {

        .h3 {
            font-size: 1.2rem;
            text-align: center;
            font-family: "Typold Regular" !important;
        }
    

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
        .h3 {
            font-size: 1.5rem;
        }
    
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
        .h3 {
            font-size: 1.8rem;
        }
    
}