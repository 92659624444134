.section{
    
    padding: 20px;
    margin-top: 5rem;
    &>div:last-child .content{
        border-bottom: none;
    }
    .sectionContainer{
        background-color: #f7f7f7;
    }
    ul{
        padding-left: 1.5rem;
        li{
            list-style: none;
        }
    }
}