@use "../../../assets/styles/custom" as config;
@use "../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {
    .wrapper {
        margin: 2rem auto;
        max-width: 800px;

        .cta {
            text-align: center;

            button {
                font-size: 1rem;
                padding: 0.8rem;

            }
        }

        .formContainer {
            margin: 1rem 0;

            .userLabel {
                display: flex;
                flex-direction: column;

                &>* {
                    width: 100%;


                }

                &>div:last-child {
                    padding-top: 1rem;
                }
            }

            display: flex;
            flex-wrap: wrap;

            &>* {
                width: 100%;
                padding: 1rem;
            }
        }
    }

    .modal {
        text-align: center;
        
        img {
            max-width: 250px;
            width: 100%;
        }
        p{
            max-width: 300px;
            margin: 2rem auto ;
        }
        button {
            font-size: 1rem;
            padding: 0.7rem;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .wrapper {
        max-width: 800px;
        margin: 5rem auto;


        .formContainer {
            margin: 3rem 0;

            .userLabel {
                display: flex;
                flex-direction: row;

                &>* {
                    width: 50%;


                }

                &>div:first-child {
                    padding-right: 1rem;
                }

                &>div:last-child {
                    padding-left: 1rem;
                    padding-top: 0px;
                }

                &>div:last-child {}
            }

            display: flex;
            flex-wrap: wrap;

            &>* {
                width: 50%;
                padding: 1rem;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}