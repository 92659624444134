@use "../../../assets/styles/custom" as config;
@use "sass:map";


@keyframes rotate-infinity {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes -rotate-infinity {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

@keyframes rotate-adapter {

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate-adapter-oposite {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(-90deg);
    }

    50% {
        transform: rotate(-180deg);
    }

    75% {
        transform: rotate(-270deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}


@media screen {
    .listContainer {
        --animation-duration: 30s;
        width: 300px ;
        height: 300px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        .centerContainer {
            position: absolute;
            height: 100%;
            width: 100%;
            max-width: 200px;
            max-height: 200px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(204, 230, 230, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            img {
                width: 100%;
                max-width: 100px;
                max-height: 100px;
                border-radius: 40%;
                box-shadow: -13.058px 7.84602px 30.4677px rgba(0, 26, 26, 0.3275), inset 4.78792px -2.87687px 5.58575px #005E5F, inset -4.78792px 2.87687px 5.58575px #009B9C;
            }
        }

        .lineContainer {
            position: absolute;
            border: 3px solid #FFA5AD;
            border-radius: 50%;
            height: 100%;
            width: 100%;
            top: 50%;
            left: 50%;

            &.first {

                .gateway {
                    animation: rotate-adapter-oposite calc(1 * var(--animation-duration)) linear infinite;
                }

                transform: translate(-50%, -50%) rotate(70deg);
                width: 100%;
                height: 100%;

                &.overlay {
                    transform: translate(-50%, -50%);
                    border: 0;
                }

                .gatewayContainer {
                    height: 100%;
                    width: 100%;
                    animation: rotate-infinity var(--animation-duration) linear infinite;

                    .gateway {
                        transform: rotate(1turn);
                        transition: transform 1s;
                    }

                    .gateway:nth-child(1) {
                        top: -5%;
                        left: 47%;
                    }

                    .gateway:nth-child(2) {
                        bottom: 35%;
                        right: -5%;
                    }

                    .gateway:nth-child(3) {
                        bottom: -5%;
                        left: 45%;
                    }

                    .gateway:nth-child(4) {
                        top: 15%;
                        left: 2%;
                    }

                    .gateway:nth-child(5) {
                        bottom: 5%;
                        left: 10%;
                    }
                }
            }

            &.last {
                transform: translate(-50%, -50%) rotate(calc(-15deg));
                width: 70%;
                height: 70%;

                .gateway {
                    animation: rotate-adapter calc(1 * var(--animation-duration)) linear infinite;
                }

                &.overlay {
                    transform: translate(-50%, -50%);
                    border: 0;

                    .gatewayContainer {
                        height: 100%;
                        width: 100%;
                        position: relative;
                        animation: -rotate-infinity var(--animation-duration) linear infinite;

                        .gateway:nth-child(1) {
                            top: 10%;
                            right: 0%;
                        }

                        .gateway:nth-child(2) {
                            bottom: 10%;
                            right: 0%;
                        }

                        .gateway:nth-child(3) {
                            top: 40%;
                            left: -6%;
                        }
                    }
                }
            }

            >.dot.start {

                --izi-size: 29.67px;
                position: absolute;
                top: 0;
                transform: translateY(-50%);
                width: calc(var(--izi-size));
                height: calc(var(--izi-size));
                left: calc(35% + calc(var(--izi-size) / 2));
                border-radius: 50%;
                background-color: var(--color-primary);
            }

            >.dot.end {

                --izi-size: 29.67px;
                position: absolute;
                bottom: 0;
                transform: translateY(50%);
                width: calc(var(--izi-size));
                height: calc(var(--izi-size));
                left: calc(35% + calc(var(--izi-size) / 2));
                border-radius: 50%;
                background-color: var(--color-primary);
            }

            .gateway {
                position: absolute;
                max-width: 50px;
                max-height: 50px;
                box-shadow: 0px 4px 250px 50px rgba(63, 63, 63, 0.1);
               
                background-color: #fff;
                border-radius: 15px;
                padding: 0.3rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: none;
                    border-radius: 50%;
                }
            }
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .listContainer{
        width: 350px ;
        height: 350px;
        .lineContainer{
            .gateway {
                max-width: 65px ;
                max-height: 65px  ;
                padding: 0.5rem;
            }
        }
        .centerContainer{
            max-width: 100px;
            max-height: 100px;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .listContainer{
        width: 400px ;
        height: 400px;
        .lineContainer {
            .gateway {
                max-width: 70px;
                max-height: 70px;
                border-radius: 20px;
                padding: 0.7rem;

            }
            position: absolute;
            border: 5px solid #FFA5AD;
        }
        
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .listContainer{
        .gateway {
            max-width: 72px;
            max-height: 72px;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}

