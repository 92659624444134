@use "../../../../assets/styles/custom" as config;
@use "sass:map";

@media screen {
    .cta-box {
        position: relative;
        display: block;
        >div {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-bottom: 3rem;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .cta-box {
        display: flex;
        flex-wrap: wrap;
        top: -6rem;
        >div {
            width: 50%;
            padding: 1rem;

        }

        >div:nth-child(2n) {
            position: relative;
            bottom: -50px;
        }

    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .cta-box {

        >div:nth-child(1),
        >div:nth-child(2) {
            padding: 2rem ;
        }

        >div:nth-child(3),
        >div:nth-child(4) {
            padding: 2rem ;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {

    .cta-box {

        >div:nth-child(1),
        >div:nth-child(2) {
            padding: 3rem ;
        }

        >div:nth-child(3),
        >div:nth-child(4) {
            padding: 3rem ;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}