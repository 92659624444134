@use "../../../assets/styles/custom" as config;
@use "sass:map";

@media screen {
    .titleSection {
        display: flex;
        flex-direction: column;

        h3 {
            margin-bottom: 0.5rem;
            margin-top: 0.25rem;
            font-size: 1.3rem;
            font-family: "Typold Bold";
        }

        h6 {
            font-weight: 400;
            font-size: 1rem;

        }

        p {
            font-family: "Typold Regular";
            font-size: 0.88rem;
        }

        &.left {
            align-items: flex-start;
        }

        &.center {
            align-items: center
        }
    }

    .titleLink {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .titleSection {
        h3 {
            margin-bottom: 1.5rem;
            font-size: 1.5rem;
            margin-top: 1rem;
        }
    }

    .titleLink {
        flex-direction: row;
        justify-content: space-between;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .titleSection {
        h3 {
            margin-bottom: 1.5rem;
            font-size: 1.75rem;
            margin-top: 0.8rem;

        }

        h6 {
            font-weight: 400;
            font-size: 1.05rem;

        }

        &.left {
            align-items: flex-start;
        }

        &.center {
            align-items: center
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}