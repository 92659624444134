@use "../../../../assets/styles/custom" as config;
@use "../../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {
    
    .advantagesContainer{
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .advantageWrapper {
        margin-top: 3rem;
    }
    .advantage{
        margin-top: 2rem;
        max-width: 250px;
        h5{
            font-size: 1.05rem;
            margin: 1rem 0px;
        }
        svg{
            width: 50px;
        }
        p{
            font-family: "Typold Regular";
            font-size: 1.1rem;
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .advantageWrapper {
        
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .advantagesContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
    .advantageWrapper {
        margin-top: 10rem;
    }
    .advantage{
        margin-top: 0rem;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .wrapper{
        max-width: 1100px;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}