@use "../../../../assets/styles/custom" as config;
@use "sass:map";



@media screen {
    .stepslide {
        display: flex;
        flex-direction: column;

        .pics {
            .picsContainer {
                &>div{
                    position: relative;
                }
                display: flex ;
                justify-content: center;
                
                .afficherOnMobile {
                    display: none;
                }
                img {
                    max-width: 400px;
                    width: 100%;;
                    transition: all 500ms  200ms;
                    opacity: 0;
                    &:nth-child(2),
                    &:nth-child(3) {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    &.active{
                        opacity: 1;
                    }
                }
            }


        }

        .item {
            cursor: pointer;
            transition: transform  500ms  200ms, opacity  500ms  200ms;
            transform:scale(0.95) translateX(-5px);
            &.active {
                opacity: 1;
                transform:scale(1.1) ;
                p {
                    
                    opacity: 1;
                    background-color: var(--slides-text-color);
                    
                    .statecss {
                        color: #fff;
                    }
                }
            }

            opacity: 1;

            p {
                background-color: var(--slides-bg-color);
                color: var(--slides-text-color);
                width: fit-content;
                font-size: 1rem;
                padding: 0.5rem 0.6rem;
                border-radius: 100px;

                span {
                    font-family: "Typold Bold";
                }
            }
        }

        .descriptions {
            display: none;
            padding: 2rem;

            .container {}
        }
        .descriptionSwipe{
            display: block;
        }
    }
}

//xs
@media screen and (max-width: 768px) {
    .stepslide  {
        .display {
            display: block;
        }
        .pics {
            .picsContainer {
                .afficherOnDesktop {
                    display: none !important;
                }
                .afficherOnMobile {
                    display: block !important;
                }
            }
        }
        .item {
            &.active {
                transform:scale(0.9) ;

                p{
                    .statecss {
                        color: #fff !important;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .stepslide {

        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        &>div {}

        .pics {}

        .item {
            max-width: 350px;

            .active {
                
            }

            p {
                font-size:  1rem;
            }
        }

        .descriptions {
            display: block;
            .container {}
        }
        .descriptionSwipe{
            display: none;
        }

    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}