.button {
    padding: 0.5rem 0.7rem;
    cursor: pointer;
    position: relative; 
    border: none;
    border-radius: 0.7rem;
    background-color: transparent;
    font-family: "Typold Medium";
    background: var(--izi-button-bg-color);
    color: var(--izi-button-color);
    transition: all 100ms;
    span:has(svg){
        padding: 0px 0.25rem;
    }
    
    &.loading{
        overflow: hidden;
        .children{
            visibility: hidden;
        }
    }
    
    &.normal{
        &:hover{
            background: var(--color-primary-gradient-horizontal);
        }
        &:active{
            background: var(--color-primary-gradient);
        }
        &:focus{
            box-shadow: 0px 0px 5px var(--color-primary);
        }
    }
    
    &.contained {}

    &.outlined {
        border: 1px solid var(--izi-primary-color);
        background: transparent;
        color: var(--izi-primary-color);
        &.normal{
            &:hover{
                background: var(--color-primary-light);
            }
        }
        
    }

    &.cleared {
        background: transparent;
        color: var(--izi-button-color);
        &.normal{
            &:hover{
                background: var(--izi-button-bg-color);
            }
        }
        
    }

    .loader{
        position: absolute;
        top:0; left:0; right: 0; bottom: 0;
        background-color: rgba(255, 255, 255, 0.267);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}