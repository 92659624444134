@use "../../../../assets/styles/custom" as config;
@use "sass:map";

@media screen {
    .banner {
        display: flex;

        flex-wrap: wrap;
        margin: 3rem 0px;

        .leftContainer {
            width: 95%;
            flex-shrink: 0;
            color: var(--color-dark-light);
            padding-left: 1.6rem;
            position: relative;

            &::after {
                content: "";
                display: block;
                width: 5px;
                border-radius: 5px;
                height: 85px;
                background-color: rgba(0, 128, 129, 0.2);
                position: absolute;
                top: 2.3rem;
                left: 12px;
            }



            h2 {
                font-size: 2rem;
                font-family: "Typold Medium";
                margin: 0.1rem 0px 1rem 0px;
            }

            h6 {
                font-family: "Typold Light";
                font-size: 0.9rem;
                color: var(--color-primary);
            }

            p {
                font-size: 0.9rem;
                line-height: 2;
            }
        }

        .rightContainer {
            position: relative;
            width: 100%;
            margin-top: 4rem;

            .rightContentContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;

                img {
                    width: 100%;
                    display: block;
                }

                div {
                    max-width: 250px;
                }

                &>span {
                    position: absolute;
                    scale: 0.7;
                    right: -5%;
                    rotate: 90deg;
                }

                div:last-child {
                    margin-top: 4rem;

                }
            }
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .banner {
        .rightContainer {
            .rightContentContainer {
                flex-wrap: nowrap;
                flex-direction: row;

                div:first-child {
                    margin-right: 4.5rem;
                }

                div {
                    width: 40%;
                    min-width: 200px;
                }

                img {
                    width: 240px;
                }

                &>span {
                    top: 0px;
                    scale: 0.6;
                    right: 33%;
                    rotate:0deg;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    
    .banner {
        .leftContainer {
            &::after {
                height: 70px !important;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .banner {
        margin: 9.5rem 0px;

        .leftContainer {    
            width: 100%;

            &::after {
                left: 8px;
            }

            h2 {
                font-size: 2.45rem;
            }

            h6 {
                font-size: 0.9rem;
            }

            p {
                font-size: 1rem;
            }
        }

        .rightContainer {
            .rightContentContainer {

                &>span {
                    scale: 0.7;
                    right: 38%;
                }
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .banner {
        flex-wrap: nowrap;

        .leftContainer {
            width: 40%;
        }

        .rightContainer {
            width: 70%;
            margin-top: 0;

            .rightContentContainer {
                justify-content: flex-end;
                margin-top: -2.8rem;

                width: 100%;

                img {
                    width: 100%;
                    max-width: 243px;
                    min-width: 200px;
                    display: block;
                }

                &>span {
                    scale: 0.7;
                    top: -2rem;
                    right: 29%;
                }
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .banner {
        .rightContainer {

            .rightContentContainer {
                div:last-child {
                    margin-right: -1.8rem;
                    margin-top: 4rem;
                }

                &>span {
                    right: 26%;
                }
            }
        }

        .leftContainer {
            &::after {

                left: -5px;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}