.fttMobl{
    /* max-height: 800px; */
    /* overflow: auto */
}

.ftMobile {
    font-size: 14px;
    /* height: 100%;
    height: 80vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 10rem; */
    margin: 5rem;
}

.dg {
    /* flex-grow: 1; */
    text-align: justify;
    /* font-family: 'Typold'; */
    /* line-height: 22px; */

    border: 1px solid rgba(0, 128, 128, 0.50);
    border-radius: 2rem;
    padding: 15px 20px;
    margin-bottom: 10rem;
}

.centre {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0.5rem;
}

.btn-confirm{
    display: flex;
    justify-content: center;
    align-items: center;
}

::-webkit-scrollbar {
    width: 0.5px; 
}

::-webkit-scrollbar-thumb {
    background-color: #008080;
    border-radius: 5px; 
}

@media (max-width: 768px) {
    .ftMobile {
        /* overflow: auto; */
        height: 80vh;
    }
    .fttMobl {
        border: none !important;
        /* width: 95% !important; */
    }

}

/* @media (max-width: 1024px) {
    .fttMobl {
        width: 100% !important;
    }
} */
@media (max-width: 1000px) {
    .ftMobile {
        margin: 5rem 0 !important;
    }
    .dg {
        font-size: 12px;
    }
}

@media (min-width: 1260px) {
    .dg {
        line-height: 23px;
    }
}

@media (max-width: 991px) {
    .fttMobl {
        /* max-height: 600px; */
        /* overflow: auto */
    }

    .dg{
        margin-bottom: 1rem;
    }
}

@media (max-width: 530px) {
    .closeIconMobile {
        width: 100%;
    }
}

@media (max-width:785px) { 
    .ftMobile {
        /* margin-top: 3rem !important; */
    }
    .dg {
        /* margin-top: 2rem; */
        font-size: 9px;
    }
}