@use "../../../../assets/styles/custom" as config;
@use "../../../../assets/styles/custom_animations";
@use "sass:map";




@media screen {

    .community {
        margin: 4rem 0px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        .contentContainer{
            margin: 3rem 0px;
        }
        h4 {
            font-size: 2.2rem;

            span:first-child {
                font-size: 1.5rem;
                color: #615D5D;
                font-family: "Typold light";
                white-space: nowrap;
            }

            span:last-child {
                font-family: "Typold ExtraBold";
            }

        }

        button {
            margin-top: 2rem;
            padding: 1rem;
            font-size: 1rem;
        }

        &>div:last-child,
        &>div:first-child {
            
            padding: 1.5rem;
            width: 100%;
            position: relative;
            max-width: 250px;
            img{
                width: 100%;
            }
        }
        &>div:first-child{
            
        }
        &>div:last-child{
            
        }
    }

}



@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .community{
        .contentContainer{
            margin: 5rem 0px;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .wrapper{
        max-width: 1280px;
    }
    .community {
        
        justify-content: space-between;
        margin: 15rem 0px 20rem 0px;

 
        
        h4 {
            font-size: 2.5rem;
            span:first-child {
                font-size: 2rem;
            }

            span:last-child {
                font-size: 3rem;
            }

        }
        &>div:last-child,
        &>div:first-child {
            max-width: 350px;
        }

        button {
            margin-top: 2rem;
            padding: 1.2rem;
            font-size: 1.1rem;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .community {
        flex-direction: row;
        justify-content: space-between;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}