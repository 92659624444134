@use "../../../../assets/styles/custom" as config;
@use "../../../../assets/styles/custom_animations";
@use "sass:map";


.bannerHeader {
    border-radius: 0px 0px 30px 30px;
    background: #F6FFFB;
}

@media screen {
    .advantages {
        margin-top: 10rem;

        h1 {
            text-align: center;
            font-size: 1.5rem;
        }

        .itemContainer{
            max-width: 360px;
            padding: 1.5rem;
        }
        .advantage {
        
            height: 100%;
            padding: 1.5rem;
            padding-top: 1rem;
            padding-bottom: 1.5rem;
            background-color: #fff;
            background: #FFFFFF;
            box-shadow: 0px 3.15942px 105.841px rgba(0, 0, 0, 0.1);
            border-radius: 15px;
            svg{
                width: 45px;
            }
            h5 {
                margin-top: 0.5rem;
                font-size: 1rem;
            }

            p {
                margin: 1rem 0px;
                font-size: 0.75rem;
                font-family: "Typold Light";
                line-height: 1.5;
            }

        }
        .advantageContainer{
            justify-content: center;
            padding: 5rem 0px;
            display: flex;
            flex-wrap: wrap;
            max-width: 1100px;
            margin: 0px auto;
        }
    }

}



@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .advantages {

        h1 {
            font-size: 2rem;
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .advantages {
        margin-top: 8rem;
        h1 {
            font-size: 2.25rem;
        }
        .advantage {
            
            
            .icon {
                svg {}
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .advantages {
        h1 {
            font-size: 2.25rem;
        }
        .advantage {
            
            
            .icon {
                svg {}
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}