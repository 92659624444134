@use "../../../../assets/styles/custom" as config;
@use "sass:map";



@media screen {
    .container {
        .descriptionContainer {
            padding: 2rem 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            p {
                //font-size: 0.95rem
                font-size: 1rem; 
                 font-weight: 400;
                

            }
            
            &>div:nth-child(2){
                &>div:nth-child(2){
                    padding-right: 0px;
                    &>div:last-child{
                        padding-right: 0px !important;
                    }
                }
            }
            &>div:nth-child(3){
                /*display: flex;*/
                display: none;
                justify-content: center;
            }
            &>div:last-child{
                display: flex;
                align-items: center;
            }
        }
    }
    .mainImage{
        max-width: 250px;
        width: 100%;
        height: 100%;
    }
    .tag {
        max-width: 380px;
        text-align: center;
        display: flex;
        flex-direction: column;
        font-family: "Typold Regular";
        h3{
            text-align: center;
        }
        h6{
            font-family: "Typold Bold";
        }
        &>div{
            padding: 1rem 0px;
        }
        p{
            color: #4E4C4C !important;
        }
        .tagImg {
            img {
                width: 53px;
                height: 55px;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .container {
        .descriptionContainer {
            padding: 2rem 0px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            p {
                //font-size: 0.89rem;
                font-size: 0.93rem;
                 font-weight: 400;
                

            }
            &>div:nth-child(1){
                display: none;
            }
            &>div:nth-child(2){
                &>div:nth-child(1){
                    padding-left: 20%;
                    padding-right: 0px;
                    &>div:last-child{
                        padding-right: 0px !important;
                    }
                }
            }
            &>div:nth-child(3){
                display: flex;
                justify-content: center;
            }
            &>div:last-child{
                display: flex;
                align-items: center;
                padding-left: 1rem;
            }
        }
    }
    .mainImage{
        max-width: 300px;
        width: 100%;
        height: 100%;
    }
    .tag {
        max-width: 380px;
        display: flex;
        padding: 20px;
        flex-direction: row;
        font-family: "Typold Regular";
        text-align: left;
        h3{
            text-align: center;
        }
        h6{
            font-family: "Typold Bold";
        }
        &>div{
            padding: 0.5rem;
        }
        p{
            color: #4E4C4C !important;
        }
        .tagImg {
            img {
                width: 53px;
                height: 55px;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}