.checkboxContainer {
    display: flex;
    align-items: center;
    position: relative;

    label {
        margin-left: 1rem;
        font-size: 0.85rem;
    }
    input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 1;
    }
    &.checked{
        label{
            color:var(--izi-primary-color);
        
        }
    }

    &:hover {
        .checkbox {
            border-color: var(--izi-primary-color);
        }

        label {
            color: var(--izi-primary-color);

        }

    }
}

.checkbox {
    display: flex;
    width: 100%;
    position: relative;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 5px;


   

    .checkIcon {
        opacity: 0;
        transition: opacity 200ms;
        svg{
            width: 13px;
            path{
                transition: opacity 100ms;
            }
        }
    }

    &.checked {

        border-color: var(--izi-primary-color);
        background-color: var(--izi-primary-color);
        ;

        .checkIcon {
            opacity: 1;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transform-origin: center;
                width: 13px;

                path {
                    stroke: #fff;
                }
            }
        }
    }

}