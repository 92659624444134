@use "./custom" as config;
@use "sass:map";

@media screen {
    .text-primary{
        color:var(--color-primary)
    }
    .rounded-full{
        border-radius: 1000px;
    }
    .w-full{
        width: 100% !important;
    }
    .w-fit{
        width: fit-content !important;
    }
    .h-full{
        height: 100% !important;
    }
    .h-fit{
        height: fit-content !important;
    }
    .bg-white {
        background-color: #fff !important;
    }
    .text-white{
        color:#fff !important;
    }
    .container {
        max-width: 1300px;
        padding: 0px 0.8rem;
        margin: 0px auto;
        width: 100%;
    }

    .container-content {
        margin: 0px auto;
    }

    ._section_title {
        font-size: 1.4rem;
    }

    ._section_subtitle {
        font-size: 0.85rem;
    }
    .flex-col{
        flex-direction: column;
    }
    .overflow-hidden {
        overflow: hidden;
    }

    .overflow-x-hidden {
        overflow-x: hidden;
        overflow-y: auto;
    }

    .flex {
        display: flex;
    }

    .justify-content-between {
        justify-content: space-between;
    }

    .justify-content-around {
        justify-content: space-around;
    }

    .justify-content-center {
        justify-content: center;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    ._section {
        margin-top: 100px;
    }
    .bold{
        font-family: "Typold Bold";
    }
    .bolded-medium{
        font-family: "Typold Medium";
    }
    .bolded {
        font-family: "Typold ExtraBold";
    }

    ._section_title {
        color: var(--color-dark);
        margin-top: 10px;
        font-family: "Typold Medium";
    }

    ._section_subtitle {
        color: var(--color-primary);
    }

    .text-center {
        text-align: center;
    }

    .flex {
        display: flex;
    }

    .align-items-end {
        align-items: flex-end;
    }

    .justify-between {
        justify-content: space-between;
    }

    .justify-center {
        justify-content: center;
    }

    .justify-space-around {
        justify-content: space-around;
    }

    .ml-0 {
        margin-left: 0px !important;
    }

    .mr-0 {
        margin-right: 0px !important;
    }

    .w-full {
        width: 100%;
    }

    .flex-wrap {
        flex-wrap: wrap;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    .align-items-center {
        align-items: center;
    }

    .d-none {
        display: none;
    }
    
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .container {
        max-width: map-get(config.$container-max-widths, "sm");
        padding: 0px 0.9rem;
    }

    .container-content {
        max-width: map-get(config.$container-max-widths, "sm");
        padding: 0px 0.9rem;
    }

    ._section_title {
        font-size: 1.5rem;
    }

    ._section_subtitle {
        font-size: 0.9rem;
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .container {
        max-width: map-get(config.$container-max-widths, "md");
        padding: 0px 1.2rem;
    }

    .container-content {
        max-width: map-get(config.$container-max-widths, "md");
        padding: 0px 0.9rem;
    }

    .small-max-width {
        max-width: 1100px !important;
    }

    ._section_title {
        font-size: 1.9rem;
    }

    ._section_subtitle {
        font-size: 1rem;
    }

    .d-md-none {
        display: none !important;
    }

    .d-md-block {
        display: block;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .container {
        max-width: map-get(config.$container-max-widths, "lg");

    }

    .container-content {
        max-width: map-get(config.$container-max-widths, "lg");
        padding: 0px 0.9rem;
    }

    .d-lg-block {
        display: block;
    }

    .d-lg-none {
        display: none;
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .container {
        max-width: map-get(config.$container-max-widths, "xl");
    }

    .container-content {
        max-width: 1060px;
        padding: 0px 0.9rem;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {
    .container {
        max-width: map-get(config.$container-max-widths, "2xl");
    }

    .container-content {

        padding: 0px 0.9rem;
    }
}

$spaceamounts: (
    0,
    1,
    2,
    3,
    4,
    6,
    7,
    8,
    9,
    5,
    10,
    15,
    20,
    25,
    30,
    35,
    40,
    45,
    50,
    75,
    100
);
$sides: (
    top,
    bottom,
    left,
    right,
);

.py-0\.5 {
    padding: 0.5rem 0px !important;
}

.py-0\.5 {
    padding: 0.5rem 0px !important;
}

@each $space in $spaceamounts {
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}px !important;
        }

        .p#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space}px !important;
        }

        .px-#{$space} {
            padding-right: #{$space}px !important;
            padding-left: #{$space}px !important;
        }

        .py-#{$space} {
            padding-top: #{$space}px !important;
            padding-bottom: #{$space}px !important;
        }

        .my-#{$space} {
            margin-top: #{$space}px !important;
            margin-bottom: #{$space}px !important;
        }

        .mx-#{$space} {
            margin-top: #{$space}px !important;
            margin-bottom: #{$space}px !important;
        }
    }
}

@each $space in $spaceamounts {
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$space}rem {
            margin-#{$side}: #{$space}rem !important;
        }

        .p#{str-slice($side, 0, 1)}-#{$space}rem {
            padding-#{$side}: #{$space}rem !important;
        }

        .px-#{$space}rem {
            padding-right: #{$space}rem !important;
            padding-left: #{$space}rem !important;
        }

        .py-#{$space}rem {
            padding-top: #{$space}rem !important;
            padding-bottom: #{$space}rem !important;
        }

        .my-#{$space}rem {
            margin-top: #{$space}rem !important;
            margin-bottom: #{$space}rem !important;
        }

        .mx-#{$space}rem {
            margin-top: #{$space}rem !important;
            margin-bottom: #{$space}rem !important;
        }
    }
}

//Responsive mixins
@mixin hide-at($breakpoint, $el) {
    @media screen and (min-width: map.get(config.$grid-breakpoints, $breakpoint)) {
        .#{el} {
            display: none;
        }
    }
}

@mixin hide-at-breakpoints {

    @each $name, $breakpoint in config.$grid-breakpoints {
        @include hide-at($breakpoint, $name+"\\:hide");
    }
}

@include hide-at-breakpoints();