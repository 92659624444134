@use "../../../../assets/styles/custom" as config;
@use "../../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {
    .wrapper{
        margin-top: 5rem;
        margin-bottom: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        p{
            font-size: 1rem;
            font-family: "Typold Light";
            margin: 2rem 0px;
        }
        h3{
            text-align: left;
        }
        button{
            padding: 0.7rem;
            font-size: 0.8rem;
            font-family: "Typold Regular";
        }
    }
    .titleContainer{
        max-width: 500px;
        width: 100%;
    }
    .banner{
       img{
        max-width: 500px;
        width: 100%;
        margin-top: 3rem;
       }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .wrapper{
        margin-top: 10rem;
        margin-bottom: 10rem;
        flex-direction: row;
        justify-content: space-between;
        button{
            padding: 0.8rem;
            font-size: 0.9rem;
        }
    }
    .titleContainer{
        max-width: 410px;
        width: 100%;
    }
    .banner{
        img{
            max-width: 510px;
         margin-top: 0rem;
        }
     }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .wrapper{
        max-width: 1100px;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}