@use "../../../assets/styles/custom" as config;
@use "../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {
    .bannerTitle {
        .subtitleContainer {
            display: flex;

            h6 {
                color: var(--color-primary);
                font-size: 1rem;
                font-family: "Typold Light";
                position: relative;


            }

            .bars {
                height: 8px;
                margin-left: 1rem;
                margin-top: 0.8rem;
                width: 150px;
                border-radius: 10px;
                background-color: var(--izi-bg-empty-bar);
                right: 0;
            }
        }

        h2 {
            font-size: 1.8rem;
            font-family: "Typold Bold";
            margin: 0.5rem 0px;
        }

        p {
            font-size: 1rem;
            margin: 1.5rem 0px;
        }

        button {
            padding: .8rem 1rem;
            font-size: 0.8rem;
        }

    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .bannerTitle {
        .subtitleContainer {

            h6 {
                font-size: 1.1rem;
            }
        }

        h2 {
            font-size: 2.1rem;
        }

        p {
            font-size: 1.1rem;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .bannerTitle {
        .subtitleContainer {

            h6 {
                font-size: 1.2rem;
            }
        }

        h2 {
            font-size: 2.5rem;
        }

        p {
            font-size: 1.3rem;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}