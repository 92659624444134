@use "../../../assets/styles/custom" as config;
@use "sass:map";

@media screen {
    .wrapperApdp {
        z-index: 100;
        display: flex;
        position: fixed;
        overflow-y: auto;
        overflow-x: hidden;
        inset: 0;
        // height: 100vh;
        backdrop-filter: blur(2px);
        width: 100wv;
        justify-content: center;
        align-items: center;
        background-color: #00000036;

        .modalContainerADP {
            max-height: calc(100vh - 65px);
            overflow: hidden;
            border-radius: 1rem;
            background-color: #fff;
            max-width: 600px;
            width: 90%;
            height: 100%;
            .modalElADP{
                height: 100%;
                padding: 2rem;
                max-height: 100%;
                width: 100%;
                overflow: auto;

                .modalContainerStylesADP {
                    padding: 1rem;
                    overflow: auto !important;
            
                    .btn {
                        padding: 0.5rem 1rem;
                        cursor: pointer;
                        position: relative; 
                        border: none;
                        border-radius: 0.7rem;
                        font-family: "Typold Medium";
                        transition: all 100ms;
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        
    }

}

@keyframes move-x {
    0% {
        transform: translateX(0px);
    }

    40% {
        transform: translateX(-20px);
    }

    60% {
        transform: translateX(10px);
    }

    80% {
        transform: translateX(-5px);
    }

    90% {
        transform: translateX(2px);
    }

    95% {
        transform: translateX(-1px);
    }

    100% {
        transform: translateX(0px);
        ;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .wrapperApdp {
 
        .modalContainerADP {

            height: fit-content;
        }

        
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}