@use "../../../../assets/styles/custom" as config;
@use "../../../../assets/styles/custom_animations";
@use "sass:map";




@media screen {

    //configuration
    .goalSection {
        --base-max-width: 1050px;
        --scale-coef: 0;
        --scale-base: 1;
        --scroll-top: -115px;
        --scroll-top-offset: 0px;
        --animation-percent: 60px;
        --animation-reverse-percent: 0px;
        --top-fixed: 0px;
        --offset-size: 0px;
        --goal-traker-height: 0px;
        --title-container-width: 444px;

        max-width: var(--base-max-width);
        margin: 0px auto;
        background: linear-gradient(360deg, #23253D 64.06%, #008080 312.93%);
        border-radius: 50px;
    }

    .titleContainerTopWrapper {
        --top-offset-span: 0px;
        position: relative;
    }

    .goalWrapper {
        margin-top: 5rem;
    }

    .goalContainer {
        display: flex;
        flex-direction: column;
        transition: all 200ms;
        max-width: var(--base-max-width);
        margin-left: auto;
        margin-right: auto;

        .titleWrapper {
            transition: none;
        }

        h1 {
            font-size: 2rem;
            color: #fff
        }

        padding: 2.5rem;

        display: flex;

        .titleContainer {
            width: 100%;

            button {
                span:first-child {
                    margin-right: 1rem;
                }

                margin-top: 2rem;
                font-size: 1rem;
                padding: 1rem;
            }
        }


        .goals {
            padding-top: 1.4rem;
            padding-left: 50px;
            margin-top: 4rem;
            position: relative;

            &::after,
            &::before {
                z-index: 0;
                position: absolute;
                content: "";
                left: 18px;
                display: inline- block;
                height: calc(100% - 170px);
                width: 0px;
                top: 0px;
                bottom: 50px;
                border-left: 1px dashed #3d3e53;
            }

            &::before {
                height: calc(var(--goal-traker-height));
                border: none;
                border-color: var(--color-primary);
                transition: height 200ms 100ms;
            }
        }

        .goal {
            z-index: 1;
            margin-bottom: 5rem;
            padding: 1rem;
            border-radius: 1rem;
            background: rgba(206, 206, 206, 0.15);
            position: relative;

            .index {
                --color: #fff;
                ----text-color: #000;
                color: var(--text-color);
                background-color: var(--color);
                width: 45px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                position: absolute;
                font-family: "Typold Bold";
                left: 0px;
                top: 0px;
                transform: translate(-120%, -50%);
            }

            p {
                color: #fff;
                font-size: 1.1rem;
                font-family: "Typold Thin";
            }
        }
    }

}



@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .goalContainer {
        h1 {
            font-size: 2.5rem;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .goalWrapper {

        .goalContainer {

            .titleContainer {
                max-width: 500px;
            }

            .titleContainer {
                position: relative;
                transform: translateY(var(--scroll-top));
                line-height: 1.5;

                button {
                    span:first-child {
                        margin-right: 3rem;
                    }
                }
            }

            h1 {
                font-size: 3rem;

                span:first-child {
                    background: -webkit-linear-gradient(var(--color-dark) 60px, #fff 0%);
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    color: var(--color-dark);
                    white-space: nowrap;
                }

                span:last-child {
                    color: #fff;
                }
            }

            .goals {
                padding-left: 100px;

                &::after {
                    left: 68px;
                }

                &::before {
                    z-index: 1;
                    left: 67px;
                    border-width: 0px;
                    width: 3px;
                    background: var(--color-primary);
                }

            }
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .goalContainer {

        transition: none;
        flex-direction: row;

        h1 {
            font-size: 3.2rem;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .goalWrapper {
        //z-index: 100;
        position: relative;

        .goalSection {
            max-width: calc(var(--base-max-width) + var(--offset-size));
            transform: scale(calc(var(--scale-base) + var(--scale-coef)));
        }

        .goalContainer {
            h1 {
                span:first-child {
                    background: -webkit-linear-gradient(var(--color-dark) var(--animation-percent), #fff var(--animation-reverse-percent));
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    color: var(--color-dark);
                    white-space: nowrap;
                }
            }

            .titleContainer {
                transform: translateY(calc(var(--scroll-top) + var(--scroll-top-offset)));
            }

        }

        .titleContainerTopWrapper {
            top: var(--top-offset-span);

        }

        .goalSection {
            &.fixed {
                .titleContainerTopWrapper {
                    position: fixed;
                }

                .goalContainer {
                    .goals {
                        margin-left: var(--title-container-width);
                    }
                }
            }
        }

    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}