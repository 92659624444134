@use "../../../../assets/styles/custom" as config;
@use "../../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {
    .imgSection {
        padding: 1rem;
        text-align: center;
        
        img {

            width: 100%;
            max-width: 550px;
        }
    }

    .ctaBtnContainer {
        margin-top: 1rem;

        .btn {
            padding: 0.8rem;
            font-size: 0.7rem;
            border-radius: 10px;
            font-family: "Typold Regular";
            ;
            align-items: center;

            &:hover {
                svg {
                    animation: move-x 500ms 200ms;
                }
            }

            span {
                padding: 0;

                svg {
                    margin-left: 0.5rem;
                    width: 20px;
                    padding: 0;
                }
            }
        }
    }

}

.top {
    margin-top: 15px;

    .sendData {
        font-size: 14px;

        b{
            color: #008080;
        }
    }
}

.ctaButton {
    width: 30%;
    margin-top: 2rem;
    font-size: 1rem !important;
    padding: 0.7rem;
}

.modal {
    max-width: 40% !important;
    padding: 2rem;
    overflow: auto !important;
    height: 100%;
    .ctaWidth {
        width:100%;
    }
    .headerZone {
        display: flex;
        justify-content: flex-end;
    }

    .closeBtn {
        padding: 0.3rem;
        border: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
        color: #fff;
        background-color: var(--izi-primary-color);
    }
    .content{
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 500px) {
    .modal{
        max-width: 90% !important;
        height: 60% !important;
    }
}


@media screen and (min-width: 501px) and (max-width:900px) {
    .modal{
        max-width: 70% !important;
        height: 60% !important;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .imgSection {
        text-align: right;
        
        
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}


//custum input type file
.customFileInput {
    display: inline-block;
    width: 100%;
    margin-top: 20px;
  }
  
  .fileInputButton {
    display: inline-block;
    color: #000;
    border: 2px solid #008080;
    border-radius: 4px;
    padding: 12px 20px;
    cursor: pointer;
    width: 100%;
    border-radius: 10px;
  }
  
  .icon {
    margin-right: 8px;
  }
  
  .hiddenInput {
    display: none;
  }