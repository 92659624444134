._link {
    background-color: var(--color-primary-light);
    border-radius: 10px;
    padding: 0.8rem 1.5rem;
    cursor: pointer;
    .text {
        font-size: 1rem;
        color: var(--color-primary);
        margin-right: 10px;
        font-family: "Typold Medium";
    }
    .icon {
        svg {
            width: 40px;
        }
    }
}