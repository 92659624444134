@use "../../../../assets/styles/custom" as config;
@use "sass:map";

@media screen {
    .containerBanner {
        background-color: #f7f7f7;
        margin-top: -1rem;
        background-image: url("../../../../../public/images/breadcrumb-bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;

        .bannerTitle {
            color: var(--color-dark-light);
            padding: 5rem 20px;

            h2 {
                font-size: 1.8rem;
            }
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .containerBanner {
        .bannerTitle {
            padding: 5rem 20px;

            h2 {
                font-size: 2rem;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .containerBanner {
        .bannerTitle {
            padding: 7rem 20px;

            h2 {
                font-size: 2.5rem;
            }
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}