@use "../../../assets/styles/custom" as config;
@use "../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {
    .container{
        padding: 0px 1rem;
        padding-top: 5rem;
        text-align: center;
        max-width: 500px;
        margin: 0px auto;
        img{
            max-width: 150px;
        }
        p{
            margin: 1.5rem 0px;
        }
    }
    
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
   
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}