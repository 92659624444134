@use "../../../../assets/styles/custom" as config;
@use "../../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {
    .bannerContainer {
        display: flex;
        flex-direction: column;
        margin: 2rem 0px;

        .bannerImg {
            position: relative;
            max-width: 300px;
            flex-shrink: 0;
            margin: 3rem auto;
            img {
                width: 100%;

            }
        }

        .userFlag {
            top:0%;
            transform: translateX(-50%);
            width: 100%;
            max-width: 170px;
            align-items: center;
            display: flex;
            position: absolute;
            background: #FFFFFF;
            box-shadow: 0px 4px 100px 35px rgba(0, 0, 0, 0.1);
            border-radius: 18px;
            padding: 1rem;
            img {
                &.userImg {
                    top: 0;
                    transform: translate(-50%, -50%);
                    left: 0px;
                    width: 40px;
                    position: absolute;
                }

                width: 35px;
            }
            p{
                font-family: "Typold Bold";
                font-size: 0.8rem;
            }
        }
        .tag{
            position: absolute;
            width: 100%;
            right: 0;
            transform: translateX(20%);
            top:65%;
            svg{
                width: 18px;
            }
            div{
                display: inline-flex;
                justify-content: space-between;
                font-size: 0.8rem;
                font-family: "Typold Light";
                span:last-child{
                    margin-left: 1rem;
                }
            }
            
            p{
                font-size: 0.8rem;
                font-family: "Typold Bold";
            }
            padding: 0.8rem;
            border-radius: 18px;
            max-width: 140px;
            background: #FFFFFF;
            box-shadow: 0px 4px 100px 35px rgba(0, 0, 0, 0.1);
        }
        .bannerTitle{
            max-width: 500px;
        }
        .arrowFlag{
            position: absolute;
            top:25%;
            left: 0;
            transform: translateX(-50%);
            svg{
                transform: rotateY(180deg) rotateZ(90deg);
            }
        }
    }




}

@media screen and (max-width: 768px) {
    .bannerContainer {
        .userFlag, .arrowFlag {
            display: none !important;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    
    .bannerContainer {
        .bannerImg {
            margin: 0;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //align-items: center;
        .bannerTitle{
            margin-top: 3rem;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .bannerContainer {
        .bannerImg {
            max-width: 265px;
            flex-shrink: 0;
            margin: 0;
        }
        .userFlag {
            top:28%;
            transform: translateX(-120%);
        }
        .arrowFlag{
            transform: translateX(-50%);
            svg{
                transform: none;
            }
            //transform: rotateY(180deg) rotateZ(90deg);
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .wrapper{
        max-width: 1080px;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}