@use "../../../../assets/styles/custom" as config;
@use "../../../../assets/styles/custom_animations";
@use "sass:map";


@media screen {
    .bannerCta {
        border-radius: 40px;
        padding: 1rem 2rem 2rem 2rem;
        background-color: var(--color-primary-blue);
        color: #fff;
        display: flex;
        flex-direction: column;

        .imgContainer {
            position: relative;
            width: fit-content;
            height: 100%;

            .cryptos_cta {
                position: absolute;
                right: 58%;
                top: 32%;
                max-width: 200px;
            }

            span {
                position: absolute;
                top: 33%;
                z-index: 10;
                right: 70%;
                scale: 0.6;
            }
        }

        .leftContainer {
            margin-top: 2rem;
        }

        p {
            font-family: "Typold Regular";
            font-size: 1rem;

            margin-bottom: 1.5rem;
        }

        &>div {
            width: 50%;

            &.rightContainer {
                display: flex;
                justify-content: center;
            }
        }

        h3 {
            // font-family: "Typold Regular";
            margin-bottom: 1rem;
        }

        .button {
            --color-primary-gradient-horizontal: #fff;
            --izi-button-bg-color: var(--color-primary-gradient-horizontal) !important;
            --izi-button-color: var(--color-primary-blue) !important;
            padding: 1rem;
            font-size: 0.7rem;

            svg {
                path {
                    fill: var(--izi-button-color);
                }

            }

            &:active {
                --color-primary-gradient-horizontal: #fff;
                --color-primary-gradient: #fff;
                --izi-button-bg-color: var(--color-primary-gradient-horizontal) !important;
                --izi-button-color: var(--color-primary-blue) !important;

                svg {
                    animation: move-x 500ms 200ms !important;
                }
            }


            &:hover {
                svg {
                    animation: move-x 500ms 200ms;
                }
            }
        }

        img {
            width: 100%;
            max-width: 420px;
        }

        &>div {
            width: 100%;

        }

        .note {
            top: 20%;
            left: 63%;
            box-shadow: 0px 25.5123px 85.041px 29.7643px rgba(0, 0, 0, 0.1);
            ;
            color: #000;
            position: absolute;
            display: flex;
            font-size: 0.7rem;
            background-color: #fff;
            padding: 0.6rem;
            border-radius: 15px;

            .noteDescripton {
                max-width: 60px;
            }

            .checknote {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }

            p {
                font-size: 0.4rem;
                margin: 0px;
                margin-top: 0.3rem;
                line-height: 0.6rem;
            }

            .imgsCaps {
                display: flex;
                width: 50px;
            }

            img {
                max-width: 25px;
                width: 100%;

                &:last-child {
                    position: relative;
                    left: -5px;
                }
            }
        }

        .rightContainer {
            position: relative;

            // .note {

            //     .checknote {}
            // }
        }
    }


}

@media screen and (max-width: 768px) {
    .bannerCta  {
        .imgContainer {
            .imgContent, .sym {
                display: none;
            }
        }

        .note {
            display: none;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .bannerCta {

        .imgContainer {
            position: relative;

            .cryptos_cta {
                position: absolute;
                right: 58%;
                top: 32%;
                max-width: 300px;
            }

            span {
                position: absolute;
                top: 36%;
                z-index: 10;
                right: 71%;
                scale: 0.6;
            }

        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .bannerCta {
        padding: 0rem 3rem 2rem 3rem;

        .imgContainer {
            position: relative;

            .cryptos_cta {
                position: absolute;
                right: 58%;
                top: 32%;
                max-width: 300px;
            }

            span {
                position: absolute;
                top: 36%;
                z-index: 10;
                right: 71%;
                scale: 0.6;
            }

        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .bannerCta {
        padding: 0rem 4.5rem 2rem 4.5rem;

        h3 {
            max-width: 450px;
            font-size: 2rem;
            line-height: 3rem;
        }

        p {
            max-width: 390px;
            font-size: 1.1rem;
            line-height: 2rem;
        }

        flex-direction: row;

        &>div {
            width: 50%;

            &.rightContainer {
                display: flex;
                justify-content: flex-end;
            }
        }

        .button {
            font-size: 0.9rem;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}