@use "../../../../assets/styles/custom" as config;
@use "../../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {
    .bannerContainer {
        padding: 3rem 0px;
        display: flex;

        .animationContainer{
            display: none;
        }


        .headSubtitle {
            font-size: 1rem;
            font-family: "Typold Medium";
            margin-bottom: 0.5rem;
        }

        .headTitle {
            font-size: 1.8rem;
            line-height: 1.2;
            font-family: "Typold ExtraBold";

            .flexDisplayOnMobile {
                display: none !important;
            }
        }

        .subHeader {
            font-size: 0.9rem;
            margin-top: 0.5rem;
        }

        .input {
            align-items: flex-end;
            
            .textfield {
                padding: 0px;
                margin-top: 3rem;
                background: #F6FFFF;
                border-radius: 13px;
                border-width: 0px;
                input {
                    border: 1.5px solid var(--color-primary) !important;
                    border-radius: 13px;
                    padding: 0.7rem;
                    border: none;
                    font-family:"Typold Medium" ;
                    &::placeholder {
                        color: var(--color-dark);
                        font-family:"Typold Regular" ;
                        font-size: 0.85rem;
                    }
                }
            }

            &>div:first-child {

                width: 100%;
            }

            &>div:last-child {
                margin-left: 1rem;
                ;
                display: flex;
                align-items: center;

                .button {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    white-space: nowrap;
                    font-family: "Typold Medium";
                    font-size: 0.7rem;
                    padding: 0.9rem;
                    border-radius: 12px;

                    &:hover {
                        svg {
                            animation: move-x 500ms 200ms;
                        }
                    }

                    span {
                        padding: 0;

                        svg {
                            margin-left: 0.5rem;
                            width: 20px;
                            padding: 0;
                        }
                    }
                }
            }
        }

    }
    .errorNotif{
        color: red;
        font-size: 0.8rem;
        margin-top: 0.5rem;
    }
    
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .bannerContainer {
        .headSubtitle {
            font-size: 1.2rem;
        }

        .headTitle {
            font-size: 2rem;
        }

        .subHeader {
            font-size: 1.2rem;
        }

        .input {
            &>div:last-child {
                .button {
                    font-size: 1rem;
                    span {
                        padding: 0;

                        svg {
                            margin-left: 1rem;
                            width: auto;
                            padding: 0;
                        }
                    }
                }
            }
            .textfield {

                input {
                    padding: 0.9rem;
                }
                .button {
                    padding: 0.5rem;
                }
            }
        }


    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .bannerContainer {
        &>div:first-child {
            padding-right: 2rem;
        }
        
    }
    

}

@media screen and (max-width: 768px) {
    .bannerContainer {
        .headTitle {
            .flexDisplayOnMobile {
                display: block !important;
            }
            .flexDisplayOnDesktop {
                display: none !important;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .bannerContainer {
        padding: 4rem 0px;
        padding-bottom: 0px;



        .headSubtitle {
            font-size: 1.5rem;
        }

        .headTitle {
            font-size: 3.15rem;
        }

        &>div {
            width: 50%;
        }

        .input {
            &>div:last-child {
                .button {
                    font-size: 1rem;
                }
            }

            .textfield {}

            .input {}

            .button {
                padding: 0.5rem;
            }
        }
    }
    .animationContainer{
        display: flex !important;
        justify-content: flex-end;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .bannerContainer {
        &>div:first-child {
            padding-right: 7rem;
        }
    }
    .animationContainer{
        > div{ 
           width: 600px;
           height: 600px;
           position: relative;
           top:-50px;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}