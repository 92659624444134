.container{
    padding-left: 15px;
}
.progress {
    position: relative;
    margin: 20px 0px;
    --progress:0%;
    
    .bar {
        margin-top: 4rem;
        height: 10px;
        border-radius: 20px;
        overflow: hidden;
        background-color: #E6F0FF;
        position: relative;
        &::after{
            content:"";
            display: block;
            width: 100%;
            background-color: var(--color-primary-blue);
            height: 10px;
            position: absolute;
            transition: width 200ms;
            left: 0;
            width: var(--progress);
            top:0;
        }
    }

    .bubleContainer {
        position: absolute;
        bottom: -6px;
        left: var(--progress);
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            background-color: #E6F0FF;
            padding: 0.3rem 0.5rem;
            font-size: 0.6rem;
            font-family: "Typold Regular";
            color: #2A00A2;
            border-radius: 10px;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                display: block;
                top: 90%;
                left: 50%;
                transform: translateX(-50%);
                border: 5px solid transparent;
                width: 0px;
                height: 0px;
                border-top-color: #E6F0FF;

            }
        }

        .bulble {
            margin-top: 12px;
            display: block;
            width: 23px;
            height: 23px;
            outline: 6px solid #E6F0FF;
            border-radius: 25px;
            background-color: var(--color-primary-blue);

        }
    }
}