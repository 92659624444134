@use "../../../assets/styles/custom" as config;
@use "sass:map";

@media screen {
    .appDownloader {
        display: flex;
        width: fit-content;
        margin-top: 2rem;
        flex-wrap: wrap;

       

        .btnContainer {
            display: inline-flex;
            flex-direction: row;

            button:last-child {
                margin-left: 1rem;

                .content {
                    span:last-child {
                        font-size: 2rem;

                    }
                }
            }
        }

        button {
            padding: 0.1rem 0.6rem 0rem;
            display: inline-flex;
            align-items: center;
            background-color: transparent;
            border: 0.5px solid #fff;
            border-radius: 7px;

            .content {
                margin-left: 0.25rem;
                display: inline-block;
                text-align: left;
                color: #fff;
                align-items: center;

                span:first-child {
                    font-size: 0.5rem;
                    line-height: 5px;
                    font-family: "Typold ExtraThin";

                    svg {}
                }

                span:last-child {
                    margin-top: .1rem;
                    line-height: 0;
                    font-family: "Typold Regular";
                    font-size: 1rem;
                }

                span {
                    display: block;
                }
            }
        }

        

        .qrCode {
            max-width: 286px;
            margin-top: 20px;
            margin-left: 0px;
            //display: inline-flex;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.9rem;
            background-color: rgba(97, 161, 255, 0.34);
            border-radius: 10px;
            .qr{
                width: 100px;
                height: 70px;
            }

            &>div {
                padding: 0rem 0.8rem 0rem 1rem;

                &>* {
                    width: fit-content;
                }
            }

            p {
                //font-size: 0.62rem;
                font-size: 0.66rem;
                line-height: 1rem;
                margin-bottom: 0.25rem;
                font-family: "Typold Thin";

                // span {
                //     font-size: 0.70rem;
                //     font-family: "Typold Medium";
                // }
            }

            h6 {
                font-size: 0.6rem;
                font-family: "Typold Book";
            }
            
        }
        // .leftView {
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     align-items: center;
            
        // }

        &.colored {
            .qrCode{
                background-color: var(--section-color);
            }
            .btnContainer {
                button {
                    background-color: var(--section-color);
                    border-color: var(--section-color);
                }
            }

        }
    }

}


@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .appDownloader {
        flex-wrap: nowrap;

        .btnContainer {
            flex-direction: column;


        }

        .btnContainer {


            button:last-child {
                margin-top: 1rem;
                margin-left: 0px;
            }
        }

        .qrCode {
            max-width: 268px;
            margin-left: 20px;
            margin-top: 0px;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}