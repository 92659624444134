@use "../../../../assets/styles/custom" as config;
@use "sass:map";




@media screen {
    .blogListContainer {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .slideContainer{
            .slideContainerTitle{
                margin-bottom: 2rem;
            }
            width: 100%;
            // .swiper-wrapper {
            //     background-color: "red" !important;
            // }
        }
        .navigationContainer {
            &:first-child{
                display: none;
            }
            &:last-child{
                display: flex;
                
            }
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            button:last-child{
                margin-left: 1rem;
                
            }
            button:first-child{
                svg{
                    rotate: -180deg;
                }
            }
            button:disabled{
                svg{
                    path{
                        stroke: #ccc;
                    }
                }
            }
            button.navigation {
                background-color: transparent;
                border: none;
                border-radius: 100%;
                display: inline-flex;
                svg {
                    width: 40px;
                    height: 40px;;
                }
            }
        }

        .blogItem{
            max-width: 400px;
            width: 100%;
            border-radius: 30px;
            border: 1px solid var(--color-primary);
            box-shadow: 0px 4px 80px rgba(17, 81, 81, 0.08);
            .descriptionContainer{
                padding: 1.5rem 1rem 2rem 1rem;
                
                &>div:last-child{
                    display: flex;
                    margin: 0.8rem 0px;
                }
            }
            
            h6{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 0.9rem;
                font-family: "Typold Medium";
                font-weight: 400;
                color: #000 !important;
            }
            p{
                font-size: 0.7rem;
                display: flex;
                align-items: center;
                color: #000 !important;
                &:last-child{
                    // margin-left: 1rem;
                }
            }
            svg{
                width: 0.8rem;
                margin-top: 4px;
                margin-right: 0.5rem;
            }
            .imgContainer{
                background-image: var(--izi-img-src);
                height:  178px;
                border-radius: 30px;
                position: relative;
                img{
                    object-fit: cover;
                    position: absolute;
                    border-radius: 30px;
                    width: calc(100% + 2px);
                    height: calc(100% + 1px);
                    // width: 100%;
                    // height: 100%;
                    // top:0;
                    // left:0;
                    top: -1;
                    left: -1px;
                    bottom: 0px;
                    right: -1px;
                }
            }
        }

    }
}


@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .blogListContainer{
        flex-direction: row;
        --izi-navigation-container-width:10%;
        .navigationContainer{
            &:first-child{
                display: flex;
            }
            &:last-child{
                display: none;
            }
            flex-direction: column;
            justify-content: flex-start;
            padding-top: 7rem;
            width: var(--izi-navigation-container-width);
            button:last-child{
                margin-top: 1rem;
                margin-left: 0px;
            }
        }
        .slideContainer{
            width: calc( 100% - var(--izi-navigation-container-width) );
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .blogListContainer{
        padding: 0px 4rem;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}