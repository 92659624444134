@use "../../../../assets/styles/custom" as config;
@use "sass:map";

@media screen {
    .banner{
        .inner{display: none;}
        .onMobile {
            display: block !important;
        }
    }
    .bannerContainer {
        padding: 3rem 0px;

        .subtitlebarContainer {
            display: flex;
            .subtitlebar {
                margin-left: 10px;
                position: relative;

                &::after {
                    content: "";
                    display: block;
                    width: 100px;
                    height: 10px;
                    border-radius: 10px;
                    background-color: var(--izi-bg-empty-bar);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
           
        }
        .headerTitle{
            font-family: "Typold Bold";
            margin:1rem 0px 0px 0px;
        }
        .headerDescription{
            //font-family: "Typold Medium";
            margin:2rem 0px 0px 0px;
            font-size: 1.2rem;
        }

    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .banner{
        margin-top: 2rem;
        display: flex;
        .onMobile {
            display: none !important;
        }
        .inner{
            position: relative;
            display: block;
            svg{
                position: relative;
                scale: 1.4;
                left: -3rem;
                bottom: 3rem;
                transition:all 100ms;
                width: 100%;
            }
        }
        & > div{
            width: 50%;
        }
    }
    .bannerContainer {
        .subtitlebar {
            &::after {
                width: 150px;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .banner{
        margin-top: 4rem;
        .onMobile {
            display: none !important;
        }
        .inner{
            svg{
                //position: relative;
                scale: 1.3;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .banner{
        .onMobile {
            display: none !important;
        }
        .inner{
            svg{
                //position: relative;
                scale: 1.15;
                left: -2rem;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .banner {
        .onMobile {
            display: none !important;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {
    .banner {
        .onMobile {
            display: none !important;
        }
    }
}