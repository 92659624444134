.alert{
    padding: 20px  ;
    border-radius: 20px;
    align-items: center;
    display: flex;
    p{
        font-size: .85rem;
    }
    .btnContainer{
        padding-left: 1rem;
    }
    &.danger{
        background-color: var(--color-danger-light);
        color:var(--color-danger);
    }
    &.success{
        background-color: var(--color-primary-light);
        color:var(--color-primary);
    }
   
    .btn{
        padding: 5px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.034) ;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: none;
        &:hover{
            background-color: rgba(0, 0, 0, 0.082);
        }
    }
}