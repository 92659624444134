@use "../../../../assets/styles/custom" as config;
@use "sass:map";

@media screen {

    .accordion {
        
        font-family: "Typold Regular";


        //font-family: "Typold Regular";
        h5 {
            font-size: 1rem;
            &.opened{
                color: var(--color-primary);
            }

        }

        p {
            // font-style: italic;
            font-size: 13px
        }

        .titleSection {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            cursor: pointer;
            padding: 1rem 0px;
            margin: 0px 2rem;
            border-bottom: 1px solid #ddd;

            .iconBtn {
                button {
                    line-height: 0;
                    border: 1px solid #ddd;
                    padding: 0.72rem 0.5rem;
                    border-radius: 100%;
                    display: inline-block;
                    transform: rotateZ(-90deg);
                }
                button {
                    
                    &.on{
                        transform: rotateZ(0deg);
                        border-color: var(--color-primary);;
                        path{
                            stroke: var(--color-primary);
                        }
                    }
                }
                
                
            }
        }

        .contentContainer {
            padding: 1rem 2rem 0px 2rem;
            transition: max-height 500ms;
            max-height: max-content;
            position: relative;
            border-left: 1px solid var(--color-primary);
            &.closed {
                max-height: 0;
                overflow: hidden;
                padding: 0;
            }
            .contentWrapper {
                padding-bottom: 1rem;
                border-bottom: 1px solid #ddd;
                .content {
                    padding: 2rem;
                    background-color: #fff;

                    
                }
            }

        }

    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}