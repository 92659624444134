@use "../../../../assets/styles/custom" as config;
@use "sass:map";



@media screen{
    .section{
        display: flex;
        flex-direction: column;
        &>div{
            width: 100%;
            &:last-child{
                display: flex;
                justify-content: center;
                margin: 5rem 0px;
                img{
                    width: 100%;
                }
            }
            &:first-child{

                
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .section{
        flex-direction: row;
        align-items: center;
        &>div{
            width: 50%;
            &:last-child{
                display: flex;
                justify-content: space-around;
            }
            &:first-child{
                padding-left: 50px;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}
