@use "../../../../assets/styles/custom" as config;
@use "../../../../assets/styles/custom_animations";
@use "sass:map";


.wrapper {
    --izi-color-bg: linear-gradient(180deg, #3257A8 0%, #23253D 166.57%);
}

@media screen {

    .wrapper {
        --izi-color-bg: linear-gradient(180deg, #3257A8 0%, #23253D 166.57%);

        padding: 0px;
        margin: 6rem 0px;

    }

    .buttoncta {
        --color-primary-gradient-horizontal: transparent;
        font-size: 0.8rem !important;
        padding: 0.8rem !important;
        border: 2px;

        &:hover {
            background-color: transparent !important;
        }
    }

    .ctaContainer {
        position: relative;

        canvas {
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;

        &>div {
            flex: 1;
            padding: 2rem 1rem;
        }

        border-radius: 20px;
        padding: 4rem 2rem;
        background: var(--izi-color-bg);
        color:#fff;

        h1 {
            font-size: 2.2rem;
            font-family: "Typold ExtraBold";
        }

        p {
            margin-top: 0.5rem;
        }

        .cta {
            margin-top: 1rem;

            h1 {
                margin-top: 0.5rem;
            }
        }
    }

    .ctaSection {
        padding: 0;

        h1 {
            font-size: 2rem;
        }

        .ctaImg {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            display: none;

            svg {
                width: 70%;
            }
        }

        .cta {
            margin: 0;
            padding-top: 2rem;
            padding-bottom: 2rem;

            button {
                font-size: 1rem;
                display: inline-flex;
                align-items: center;
                padding: 1rem;
                border: 2px solid var(--color-primary);
                background: transparent;
                position: relative;
                z-index: 1;
                transition: all 500ms 100ms;

                &:hover {
                    background-color: var(--color-primary);
                    color: #fff;

                    svg {
                        animation: move-x 500ms 200ms;
                    }
                }

                span:last-child {
                    margin-left: 1rem;
                }
            }

            p {
                font-size: 1rem;
            }

            h1 {
                font-size: 1.7rem;
            }

            div {
                margin-top: 1.5rem;
            }
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {

    .ctaContainer {
        h1 {
            font-size: 2.8rem;
        }

        border-radius: 30px;

    }

    .buttoncta {
        font-size: 1rem !important;
        padding: 1rem !important;
    }

    .ctaSection {
        padding: 0px 5%;

        .cta {

            p {
                font-size: 0.8rem;
            }

            h1 {
                font-size: 2rem !important;
            }

            div {
                margin-top: 1.5rem;
            }
        }

        .ctaImg {
            display: flex;

            svg {
                height: 50%;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .wrapper {
        padding: 0px;
    }

    .ctaContainer {
        flex-direction: row;



        h1 {
            font-size: 2.5rem;
        }

        &>div {
            padding: 0px 1rem;
        }

        .cta {
            flex: 2;

            h1 {
                font-size: 2.5rem !important;
            }
        }


    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .wrapper {
        padding: 0px 4rem;
    }

    .ctaContainer {
        h1 {
            font-size: 2.8rem;
        }

        p {
            margin-top: 0.5rem;
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}