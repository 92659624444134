.formContainer {
    --izi-border-color: var(--izi-primary-color);

    input {
        border: 0px;
        background-color: transparent;
        width: 100%;
        padding: 0.8rem 0.7rem;
        outline: none;
        font-size: 1rem;
    }

    .inputContainer {
        position: relative;
        display: flex;
        overflow: hidden;
        border: 2px solid var(--izi-border-color);
        align-items: center;
        border-radius: 10px;

        &:focus-within,
        &:focus,
        &:focus-visible {
            //outline: 5px solid var(--izi-bg-color);
        }

    }


    p {
        font-size: 0.98rem;
        margin-bottom: 0.8rem;
    }

    .errorMessage {
        font-size: 0.7rem;
        color: var(--izi-primary-danger-color);
    }

    &:hover {

        p {
            color: var(--izi-border-color);
        }
    }

    &.error,
    &.error:hover {
        .inputContainer {
            border-color: var(--izi-primary-danger-color);
        }

        p {
            color: var(--izi-primary-danger-color) !important;
        }

        input {
            color: var(--izi-primary-danger-color);
        }
    }

    &.focus {
        .inputContainer {
            border-width: 3px;
        }
    }

    &.lined {
        .inputContainer {
            border-bottom: 2px solid var(--izi-primary-color);
            border-top: 0px;
            border-left: 0px;
            border-radius: 0px;
            border-right: 0px;
        }
    }

}


.phoneInputContainer {
    .inputContainer {
        width: 100%;
        position: relative;
        overflow: visible;

        .endAdornmentContainer {
            height: 100%;
            padding: 0px 10px;
            width: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.selectContainer {
    &:hover {

        p {
            color: var(--izi-border-color);
        }
    }

    p {
        font-size: 0.98rem;
        margin-bottom: 0.8rem;
    }


    .selectBox {

        .Control {
            border: 2px solid var(--izi-border-color);
            border-radius: 10px;
            padding: 5px 0px;
            outline: none !important;
            transition: all 200ms;
            box-shadow: none;

            &:hover {
                border-color: var(--izi-border-color);
            }
        }

        .Menu {
            border-radius: 20px;
            overflow: hidden;
        }

        .OptionContainer {
            padding: 0px 10px !important;

            .Option {
                border-radius: 10px !important;
            }

            &:hover {
                padding: 0px 10px !important;

                .Option {

                    border-radius: 10px !important;
                    background: var(--color-primary-light-secondary) !important;

                }
            }

            .Option:hover {
                background: var(--color-primary-light-secondary) !important;

            }
        }

    }

    &.error {
        p {
            color: var(--izi-primary-danger-color) !important;
        }

        .selectBox {
            .Control {
                border-color: var(--izi-primary-danger-color) !important;
                ;
            }
        }

    }

    .errorMessage {
        font-size: 0.7rem;
        color: var(--izi-primary-danger-color);
    }
}

.options {
    display: inline-flex !important;
    align-items: center !important;

    img {
        width: 1.3rem !important;
        margin-right: 1rem !important;
    }


}

