@use "../../../../assets/styles/custom" as config;
@use "sass:map";



@media screen {
    .showOnMobile {
        display: block !important;
    }

    .hideOnMobile {
        display: none  !important;
    }

    .sectionContainer {
        .leftContainer {
            .centered {
                display: flex ;
                flex-direction: column;
                align-items: center;
            }

            width: fit-content;
            position: relative;
            width: 100%;

            &.animate {
                animation: marquee-left 30s infinite linear;
            }
        }

        .containerList {
            width: fit-content;
            --item-bg-color: transparent;
            display: flex;
            justify-content: space-between;



            .countryItem {
                margin-right: 1rem;

                h4 {
                    font-size: 1.35rem;
                    font-family: "Typold Regular";
                    white-space: nowrap;
                }

                padding: 0.6rem 1.5rem;
                // width: fit-content;
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--item-bg-color);
                font-size: 2rem;

                img {
                    max-width: 28px;
                    margin-right: 1rem;
                    border-radius: 100px;
                }
            }
        }
    }


}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .showOnMobile {
        display: none !important;
    }

    .hideOnMobile {
        display: block !important;
    }

    .sectionContainer {
        .containerList {

            .countryItem {
                h4 {
                    //font-size: 1.6rem;
                }
            }
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {

    .sectionContainer {
        .containerList {

            .countryItem {
                h4 {
                    //font-size: 1.6rem;

                }
            }
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    .sectionContainer {
        max-width: 1050px;
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}

@keyframes marquee-left {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-50%);
    }
}

@keyframes marquee-right {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(50%);
    }
}