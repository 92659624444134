.telFormContainer{
    align-items: center;
    .iti--allow-dropdown{
        width: 100%;
    }
    
    border: 2px solid var(--izi-border-color);
    border-radius: 10px;
    input{
        border: 0px;
        background-color: transparent;
        font-size: 1rem;
    } 
    .selected-flag *{
        transition: none;
    }
    // iti__standard 
    .iti__country-list{
        border-radius: 10px;
        top: calc( 100% + 5px );
        .iti__country{
            &.iti__highlight{
                margin: 0px 10px;
                border-radius: 10px;
                background: var(--color-primary-light-secondary);
            }
        }
    }
}