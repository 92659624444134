@use "../../../assets/styles/custom" as config;
@use "../../../assets/styles/custom_animations";
@use "sass:map";

@media screen {
    .spinner{
        width: 50px;
        height: 50px;
        &::before{
            border-color: var(--color-primary);
        }
    }
    .container {
        text-align: center;
        padding-top: 4rem;
        .el{
            max-width: 350px;
            margin: 0px auto;
        }
        p{
            color:#626262;
        }
        h3 {
            font-family: "Typold ExtraBold";
            font-size: 1.5rem;
        }

        h5 {
            margin: 1rem 0px;
            font-size: 1.15rem;
            font-family: "Typold Bold";
        }

        button {
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: #008080;
            border-radius: 30px;
            color:#fff;
            padding: 0.15rem;
            border:0;
            svg{
                width: 35px;
                height: 35px;
            }
            .contentContainer {
                scale: 0.8;
                display: flex;
                align-items: center;
                .content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    span:first-child{
                        font-size: 0.8rem;
                        font-family: "Typold thin";
                    }
                    span:last-child{
                        line-height: 1.1;
                        font-size: 1.1rem;
                        font-family: "Typold Regular";
                    }
                    display: flex;
                    flex-direction: column;
                }
            }

        }
        .bannerBottom{
            margin-top: 2rem;
            max-width: 500px;
            margin:0px auto;
            margin-top: 4rem;
            margin-bottom: 0px;
        }
        img.logo{
            
            max-width: 50px;
            width: 100%;
        }
    }

}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {
    
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}