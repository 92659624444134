@use "../../../assets/styles/custom" as config;
@use "sass:map";


@media screen {
    .contents{
        min-height: calc(100vh - 110px);
    }
    
}  

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .page-content-container {}
    .contents{
        min-height: calc(100vh - 117px);
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .page-content-container {
        .footer {
            //position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
        }
        
    }
    
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}