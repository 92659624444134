@use "./custom" as config;
@use "sass:map";
@use "sass:list";

$grid-breakpoints: (
    xs: 0,
    sm: 640px,
    md:768px,
    lg:1024px,
    xl:1300px,
    "2xl":1536px
);



@keyframes move-x {
    0% {
        transform: translateX(0px);
    }

    40% {
        transform: translateX(-20px);
    }

    60% {
        transform: translateX(10px);
    }

    80% {
        transform: translateX(-5px);
    }

    90% {
        transform: translateX(2px);
    }

    95% {
        transform: translateX(-1px);
    }

    100% {
        transform: translateX(0px);
        ;
    }
}

@keyframes fade-show-top {
    0% {
        transform: translateY(-10%);
        opacity: 0;
        filter: blur(1px);
    }

    100% {
        transform: translateY(0%);
        opacity: 100%;
        filter: blur(0px);
    }
}

@keyframes fade-show-top-hide {
    0% {
        transform: translateY(0%);
        opacity: 100%;
        filter: blur(0px);
    }

    100% {
        transform: translateY(-10%);
        opacity: 0;
        filter: blur(1px);
    }
}

@keyframes fade-show-bottom {
    0% {
        transform: translateY(10%);
        opacity: 0;
        filter: blur(1px);
    }

    100% {
        transform: translateY(0%);
        opacity: 100%;
        filter: blur(0px);
    }
}

@keyframes fade-show-bottom-hide {
    0% {
        transform: translateY(0%);
        opacity: 100%;
        filter: blur(0px);
    }

    100% {
        transform: translateY(10%);
        opacity: 0;
        filter: blur(1px);
    }
}

@keyframes fade {
    0% {
        opacity: 0;
        filter: blur(1px);
    }

    100% {
        opacity: 100%;
        filter: blur(0px);
    }
}
@keyframes fade-hide {
    0% {
        opacity: 100%;
        filter: blur(0px);
        
    }

    100% {
        opacity: 0;
        filter: blur(1px);
    }
}

@keyframes fade-show-left {
    0% {
        left: -5%;
        opacity: 0%;
        filter: blur(1px);
    }


    100% {
        left: 0;
        opacity: 100%;
        filter: blur(0px);
    }
}
@keyframes fade-show-left-hide {
    


    0% {
        left: 0;
        opacity: 100%;
        filter: blur(0px);
    }
    100% {
        left: -5%;
        opacity: 0%;
        filter: blur(1px);
    }
}





.show-fade-left {
    --animation-duration: 500ms;
    --animation-delay: 500ms;
    --animation-duration-sm: var(--animation-duration);
    --animation-delay-sm: var(--animation-delay);
    --animation-duration-md: var(--animation-duration-sm);
    --animation-delay-md: var(--animation-delay-sm);
    --animation-duration-lg: var(--animation-duration-md);
    --animation-delay-lg: var(--animation-delay-md);
    --animation-duration-xl: var(--animation-duration-lg);
    --animation-delay-xl: var(--animation-delay-lg);
    --animation-duration-2xl: var(--animation-duration-xl);
    --animation-delay-2xl: var(--animation-delay-xl);
    position: relative;
    left: -5%;
    opacity: 0%;
    animation: fade-show-left-hide var(--animation-duration) var(--animation-delay) ease-in-out forwards;
    &.show {
        animation: fade-show-left var(--animation-duration) var(--animation-delay) ease-in-out forwards;
    }
}

.show-fade-bottom {
    --animation-duration: 400ms;
    --animation-delay: 900ms;
    --animation-duration-sm: var(--animation-duration);
    --animation-delay-sm: var(--animation-delay);
    --animation-duration-md: var(--animation-duration-sm);
    --animation-delay-md: var(--animation-delay-sm);
    --animation-duration-lg: var(--animation-duration-md);
    --animation-delay-lg: var(--animation-delay-md);
    --animation-duration-xl: var(--animation-duration-lg);
    --animation-delay-xl: var(--animation-delay-lg);
    --animation-duration-2xl: var(--animation-duration-xl);
    --animation-delay-2xl: var(--animation-delay-xl);
    transform: translateY(10%);
    opacity: 0;
    animation: fade-show-bottom-hide var(--animation-duration) var(--animation-delay) ease-in-out forwards;
    &.show {
        animation: fade-show-bottom var(--animation-duration) var(--animation-delay) ease-in-out forwards;
    }
}

.show-fade-top {
    --animation-duration: 400ms;
    --animation-delay: 900ms;
    --animation-duration-sm: var(--animation-duration);
    --animation-delay-sm: var(--animation-delay);
    --animation-duration-md: var(--animation-duration-sm);
    --animation-delay-md: var(--animation-delay-sm);
    --animation-duration-lg: var(--animation-duration-md);
    --animation-delay-lg: var(--animation-delay-md);
    --animation-duration-xl: var(--animation-duration-lg);
    --animation-delay-xl: var(--animation-delay-lg);
    --animation-duration-2xl: var(--animation-duration-xl);
    --animation-delay-2xl: var(--animation-delay-xl);
    transform: translateY(10%);
    opacity: 0;
    animation: fade-show-top-hide var(--animation-duration) var(--animation-delay) ease-in-out forwards;
    &.show {
        animation: fade-show-top var(--animation-duration) var(--animation-delay) ease-in-out forwards;
    }
}

.show-fade {
    --animation-duration: 400ms;
    --animation-delay: 1400ms;
    --animation-duration-sm: var(--animation-duration);
    --animation-delay-sm: var(--animation-delay);
    --animation-duration-md: var(--animation-duration-sm);
    --animation-delay-md: var(--animation-delay-sm);
    --animation-duration-lg: var(--animation-duration-md);
    --animation-delay-lg: var(--animation-delay-md);
    --animation-duration-xl: var(--animation-duration-lg);
    --animation-delay-xl: var(--animation-delay-lg);
    --animation-duration-2xl: var(--animation-duration-xl);
    --animation-delay-2xl: var(--animation-delay-xl);
    position: relative;
    opacity: 0;
    animation: fade-hide var(--animation-duration) var(--animation-delay) ease-in-out forwards;
    &.show {
        animation: fade var(--animation-duration) var(--animation-delay) ease-in-out forwards;
    }
}


@mixin get-breakpoints-animations-settings {

    @each $breakpoint in $grid-breakpoints {

        .show-fade-left.show {
            animation: fade-show-left var(--animation-duration-#{$breakpoint}) var(--animation-delay-#{$breakpoint}) ease-in-out forwards;
        }
        .show-fade-bottom.show {
            animation: fade-show-bottom var(--animation-duration-#{$breakpoint}) var(--animation-delay-#{$breakpoint}) ease-in-out forwards;
        }
        .show-fade.show {
                animation: fade var(--animation-duration-#{$breakpoint}) var(--animation-delay-#{$breakpoint}) ease-in-out forwards;
        }
    }

}

@include get-breakpoints-animations-settings()