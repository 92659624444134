.subtitle{
    --izi-font-size: var( --izi-font-size-subtitle);
    font-size: var(--izi-font-size);
    line-height: 1;
}
.th{
    --izi-font-size: var( --izi-font-size-th);
    font-size: var(--izi-font-size);
    line-height: 1;
}
.text{
    color: var(--izi-color);
}
.h1{
    --izi-font-size: var( --izi-font-size-h1);
    font-size: var(--izi-font-size);
    line-height: 1;
}
.h2{
    --izi-font-size: var( --izi-font-size-h2);
    font-size: var(--izi-font-size);
    line-height: 2.5rem;
}
.h3{
    --izi-font-size: var( --izi-font-size-h3);
    font-size: var(--izi-font-size);
    line-height: 2.25rem
}
.h4{
    --izi-font-size: var( --izi-font-size-h4);
    font-size: var(--izi-font-size);
    line-height: 2rem
}
.h5{
    --izi-font-size: var( --izi-font-size-h5);
    font-size: var(--izi-font-size);
    line-height: 2rem;
    line-height: 1.75rem;
}
.h6{
    --izi-font-size: var( --izi-font-size-h6);
    font-size: var(--izi-font-size);
    line-height: 1.75rem;
}
.p, .a{
    --izi-font-size: var( --izi-font-size-p);
    font-size: var(--izi-font-size);
}
.a{
    text-decoration: none;
}

