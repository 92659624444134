@use "../../../../assets/styles/custom" as config;
@use "sass:map";




@media screen {
    .pageContainer {
        max-width: 400px;
        margin: 3rem auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        >div {}

        >div:first-child {}

        >div:last-child {}

        >div:last-child {
            >div:first-child {

                >div:first-child {}
            }
        }

        .leftContainer {
            h3 {
                font-size: 1.5rem;
            }

            .svgContainer {
                display: none;
            }

            p {
                margin: 1rem 0px 0rem 0px;
                font-size: 0.9rem;
            }
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "sm")) {
    .pageContainer {
        .leftContainer {
            h3 {
                font-size: 1.7rem;
            }

            
        }
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "md")) {
    .pageContainer {
        
    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "lg")) {
    .pageContainer {
        max-width: 800px;
        margin: 5rem auto;
        flex-direction: row;
        .leftContainer {
            .svgContainer {
                display: block;
            }
            p {
                margin: 1rem 0px 4rem 0px;
                font-size: 0.9rem;
            }
        }
        >div {
            width: 50%;
            padding: 5rem;
        }

        >div:first-child {
            padding-left: 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            display: block;
        }

        >div:last-child {
            padding-right: 0px;
            padding-top: 0px;
            padding-bottom: 0px;

        }

        >div:last-child {
            >div:first-child {
                padding-top: 0px !important;

                >div:first-child {
                    margin-top: 0px !important;
                }
            }
        }

    }
}

@media screen and (min-width: map.get(config.$grid-breakpoints, "xl")) {}

@media screen and (min-width: map.get(config.$grid-breakpoints, "2xl")) {}